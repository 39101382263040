import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { connect } from 'react-redux';

import Header from '../../components/layout/Header';
import Footer from '../../components/Footer';
import HeaderSubmit from '../../components/Header';

const LeftSidebar = props => {
  const {
    children,
    sidebarToggle,
    sidebarFixed,
    footerFixed,
    contentBackground
  } = props;


  const submitClass = {
    flex: "1 1",
    display: "flex",
    padding: "0",
    flexDirection: "column",
    transition: "all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1)",
    height: "100vh"
  }
  return (
    <Fragment>
      <div className={clsx('app-wrapper', contentBackground)}>
        {!props.surveySubmit ?
        <HeaderSubmit name={props.userName} logout={props.logout} surveySubmit={props.surveySubmit}/> :null}
        <div
          className={clsx('app-main')}>
          <div
            className={!props.surveySubmit ? clsx('app-content') : null} style={props.surveySubmit ? submitClass:null} >
              { props.surveySubmit ? 
              children :
              <div className="app-content--inner">
                <div className="app-content--inner__wrapper">{children}</div>
              </div>
              }
            {/* {props.surveySubmit ? (<Footer surveySubmit={props.surveySubmit} />) : null} */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,

  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground
});

export default connect(mapStateToProps)(LeftSidebar);
