const Validation = [
  {
    id: "contains emotion of",
    validate: (answer, value) => {
      let val = value.toLowerCase();
      if (answer.value.indexOf(val) >= 0) {
        return true;
      }
      return false;
    }
  },
  {
    id: "only has",
    validate: (answer, value) => {
      let lowerAns = answer.value[0];
      let val = value.toLowerCase();
      if (answer.value.length == 1 && lowerAns.indexOf(val) >= 0) {
        return true;
      }
      return false;
    }
  }
];

export default Validation;