
import React, { Fragment } from 'react';
import CountUp from 'react-countup';
import {
    Grid,
    Card,
 
  } from '@material-ui/core';

export default function Report(props) {
    return (
        <Fragment>
            <div>
            <Grid container spacing={2} justify='center'>
            <Grid item xs={12} md={11} lg={10} >
            <Card className="card-box mb-3 d-flex flex-row flex-wrap justify-content-center">
                    <div className="py-4 px-5 d-flex align-items-center">
                        <div>
                            <span className="d-block opacity-7">Total</span>
                            <span className="font-weight-bold font-size-lg InfoText">
                                <CountUp
                                    start={0}
                                    end={props.count}
                                    duration={6}
                                    deplay={2}
                                    separator=""
                                    decimals={0}
                                    decimal=","
                                />
                            </span>
                        </div>
                    </div>

                    <div className="py-4 px-5 d-flex align-items-center">
                        <div>
                            <span className="d-block opacity-7" >Active</span>
                            <span className="font-weight-bold font-size-lg ">
                                <CountUp
                                    start={0}
                                    end={props.active}
                                    duration={6}
                                    deplay={2}
                                    separator=""
                                    decimals={0}
                                    decimal=","
                                />
                            </span>
                        </div>
                    </div>

                    <div className="py-4 px-5 d-flex align-items-center">
                        <div>
                            <span className="d-block opacity-7" >Simple Survey</span>
                            <span className="font-weight-bold font-size-lg align-items-center">
                                <CountUp
                                    start={0}
                                    end={props.SimpleSurveyCount}
                                    duration={6}
                                    deplay={2}
                                    separator=""
                                    decimals={0}
                                    decimal=","
                                />
                            </span>
                        </div>
                    </div>

                    <div className="py-4 px-5 d-flex align-items-center">
                        <div>
                            <span className="d-block opacity-7" >Ad Testing </span>
                            <span className="d-block font-weight-bold font-size-lg align-items-center">
                                <CountUp
                                    start={0}
                                    end={props.adCount}
                                    duration={6}
                                    deplay={2}
                                    separator=""
                                    decimals={0}
                                    decimal=","
                                />
                            </span>
                        </div>
                    </div>

                    <div className="py-4 px-5 d-flex align-items-center">
                        <div>
                            <span className="d-block opacity-7" >Recruitment</span>
                            <span className="font-weight-bold font-size-lg align-items-center">
                                <CountUp
                                    start={0}
                                    end={props.videoConfCount}
                                    duration={6}
                                    deplay={2}
                                    separator=""
                                    decimals={0}
                                    decimal=","
                                />
                                </span>
                        </div>
                    </div>    
                </Card>     
                </Grid>
                </Grid>
                </div>
        </Fragment>
    );
}