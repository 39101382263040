const findCommonElements = (arr1, arr2) => { 
    return arr1.some(item => arr2.includes(item)) 
} 

const areEqual = (first, second) => {
    if(first.length !== second.length){
       return false;
    };
    for(let i = 0; i < first.length; i++){
       if(!second.includes(first[i])){
          return false;
       };
    };
    return true;
 };

const Validation = [
    {
      id: "equals",
      validate: (answer, value) => {
        if (value != null){
            if (value.includes("=")){
                let out = value.split("=")
                let valueKey = out[0].trim()
                let _answer = answer.value
                if (_answer != null){
                    for (let [key, _value] of Object.entries(_answer)) {
                        if (valueKey === key){
                            if (Array.isArray(_value)){
                                let valueOptions = out[1].split(",")
                                return areEqual(valueOptions, _value)
                            }
                            else{
                                let valueOptions = out[1].trim()
                                return valueOptions === _value.trim()
                            }
                        }
                    }
                }
            }
        }
        return false
      }
    },
    {
      id: "contains",
      validate: (answer, value) => {
        let _answer = answer.value
        if (value != null){
            if (value.includes("=")){
                let out = value.split("=")
                let valueKey = out[0].trim()
                let valueOptions = out[1].split(",")
                if (_answer != null){
                    for (let [key, _value] of Object.entries(_answer)) {
                        if (valueKey.trim() === key.trim()){
                            if (Array.isArray(_value)){
                                return findCommonElements(valueOptions, _value)
                            }
                            else{
                                for(let i = 0; i < valueOptions.length; i++ ){
                                    if(valueOptions[i].trim() === _value.trim()){
                                        return true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return false
        }
    }
    
  ];

  export default Validation;