import Angry from '../../../assets/images/emotions/angry.svg'
import Sad from '../../../assets/images/emotions/sad.svg'
import Neutral from '../../../assets/images/emotions/bored.svg'
import Confused from '../../../assets/images/emotions/confused.svg'
import Cool from '../../../assets/images/emotions/cool.svg'
import Cry from '../../../assets/images/emotions/cry.svg'
import Cute from '../../../assets/images/emotions/cute.svg'
import Disgusted from '../../../assets/images/emotions/disugsed.svg'
import Drooling from '../../../assets/images/emotions/drooling.svg'
import Embarrassed from '../../../assets/images/emotions/embarresed.svg'
import Excited from '../../../assets/images/emotions/Excited.svg'
import Happy from '../../../assets/images/emotions/happy.svg'
import Hate from '../../../assets/images/emotions/hate.svg'
import Laugh from '../../../assets/images/emotions/laugh.svg'
import Love from '../../../assets/images/emotions/love.svg'
import Peaceful from '../../../assets/images/emotions/peaceful.svg'
import Scared from '../../../assets/images/emotions/scared.svg'
import Shocked from '../../../assets/images/emotions/shocked.svg'
import Sick from '../../../assets/images/emotions/sick.svg'
import Silly from '../../../assets/images/emotions/silly.svg'
import Surprised from '../../../assets/images/emotions/surprised.svg'
import Sympathy from '../../../assets/images/emotions/sympathy.svg'

export default {
  emotions: {
    angry: {
      icon: Angry,
      text: "Angry"
    },
    sad: {
      icon: Sad,
      text: "Sad"
    },
    neutral: {
      icon: Neutral,
      text: "Neutral"
    },
    confused: {
      icon: Confused,
      text: "Confused"
    },
    cool: {
      icon: Cool,
      text: "Cool"
    },
    cry: {
      icon: Cry,
      text: "Cry"
    },
    cute: {
      icon: Cute,
      text: "Cute"
    },
    disgusted: {
      icon: Disgusted,
      text: "Disgusted"
    },
    drooling: {
      icon: Drooling,
      text: "Drooling"
    },
    embarrassed: {
      icon: Embarrassed,
      text: "Embarrassed"
    },
    excited: {
      icon: Excited,
      text: "Excited"
    },
    happy: {
      icon: Happy,
      text: "Happy"
    },
    hate: {
      icon: Hate,
      text: "Hate"
    },
    laugh: {
      icon: Laugh,
      text: "Laugh"
    },
    love: {
      icon: Love,
      text: "Love"
    },
    peace: {
      icon: Peaceful,
      text: "Peaceful"
    },
    scared: {
      icon: Scared,
      text: "Scared"
    },
    shocked: {
      icon: Shocked,
      text: "Shocked"
    },
    sick: {
      icon: Sick,
      text: "Sick"
    },
    silly: {
      icon: Silly,
      text: "Silly"
    },
    surprise: {
      icon: Surprised,
      text: "Surprised"
    },
    sympathy: {
      icon: Sympathy,
      text: "Sympathy"
    }
  },
  grouping: [
    {
      text: "Five Simple Emotions",
      emotions: ['angry', 'sad', 'neutral', 'happy', 'excited']
    },
    {
      text: "Eight Primary Emotions",
      emotions: ['angry', 'sad', 'scared', 'embarrassed', 'peace', 'surprise', 'laugh', 'excited']
    },
    {
      text: "All Emotions",
      emotions: ['cool', 'happy', 'excited', 'laugh', 'love', 'peace', 'surprise', 'cute', 'drooling', 'embarrassed', 'shocked', 'silly', 'sympathy', 'angry', 'sad', 'hate', 'disgusted', 'scared', 'sick', 'cry', 'neutral', 'confused']
    }
  ]
}