const Validation = [
  {
    id: "equals",
    validate: (answer, value) => {
      let answerVal = answer.value;
      let val = value;
      if (answerVal == val) {
        return true;
      }
      return false;
    }
  },
  {
    id: "lesser than",
    validate: (answer, value) => {
      let answerVal = answer.value;
      let val = value;
      if (answerVal < val) {
        return true;
      }
      return false;
    }
  },
  {
    id: "greater than",
    validate: (answer, value) => {
      let answerVal = answer.value;
      let val = value;
      if (answerVal > val) {
        return true;
      }
      return false;
    }
  }
];

export default Validation;