import React from 'react';
import { Typography, Box } from '@material-ui/core';

export default (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      style={{height: '100%'}}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={0} style={{height: '100%'}}>{children}</Box>}
    </Typography>
  );
}