import React, { Fragment, useState } from 'react';
import { FormControlLabel, TextField, IconButton, Switch, Tooltip,Grid } from '@material-ui/core';
import { Clear, Add } from '@material-ui/icons';

export default (props) => {
  

  const [title, setTitle] = useState(props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('title') ? props.questionObject.options.title : "" :"");

  const submitDropDown = () => {
    props.updateQuestionObject({
      options: {
        row: rowValues,
        column:columnValues,
        title:title,
        type: typeValue
      }
    })
  }
  let _rowValue = props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('row') ? props.questionObject.options.row : [] :[]
  let _columnValue = props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('column') ? props.questionObject.options.column : [] : []
  let _typeValue = props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('type') ? props.questionObject.options.type : false : false
  const [typeValue, setTypeValue] = useState(_typeValue);
  const [rowValues, setRowValues] = useState(_rowValue);

  const updateRowItem = (text, index) => {
    let newValue = [...rowValues];
    newValue[index] = text;
    setRowValues(newValue);
    submitDropDown();
  }

  const removeRowItem = (index) => {
    let newValue = [...rowValues];
    newValue.splice(index, 1);
    setRowValues(newValue);
    submitDropDown();
  }

  const addNewRowItem = () => {
    let newValue = [...rowValues];
    newValue.push("");
    setRowValues(newValue);
    submitDropDown();
  }

  const [columnValues, setColumnValues] = useState(_columnValue);

  const updateColumnItem = (text, index) => {
    let newValue = [...columnValues];
    newValue[index] = text;
    setColumnValues(newValue);
    submitDropDown();
  }

  const removeColumnItem = (index) => {
    let newValue = [...columnValues];
    newValue.splice(index, 1);
    setColumnValues(newValue);
    submitDropDown();
  }

  const addNewColumnItem = () => {
    let newValue = [...columnValues];
    newValue.push("");
    setColumnValues(newValue);
    submitDropDown();
  }

  const submitType= (value) => {
    setTypeValue(value);
    props.updateQuestionObject({
      options: {
        row: rowValues,
        column:columnValues,
        title:title,
        type: value
      }
    })
  }


  return (
    <Fragment>
      <Grid container spacing={2}>
            <Grid item xs={10} lg={10} md={10}>
            <div className="mb-3">
                <TextField  variant="outlined" size="small"
                    label="Title"
                    type="text"
                    fullWidth
                    value={title}
                    onChange={(event) => {
                    setTitle(event.target.value)
                    }}
                    onBlur={submitDropDown}
                  />
              </div>
            </Grid>
              <Grid item xs={2} lg={2} md={2}>
              <div className="mb-3">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={typeValue}
                        onChange={(event) => submitType(event.target.checked)}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Multi Select"
                  />
                </div>
              </Grid>
      </Grid>
        <Grid container spacing={3}>
            <Grid item xs={6} lg={6}>
                <div className="mb-2 mt-2">
                row value
                </div>
                <Fragment>
                  {columnValues.map((e, i) => (
                    <Grid container spacing={0} key={i}>
                    <Grid item xs={11} lg={10} md={11}>
                    <TextField type="text"  size="small" multiline fullWidth={true} className="mb-1" variant="outlined" value={e} onChange={(event) => updateColumnItem(event.target.value, i)} onBlur={submitDropDown} />
                    </Grid>
                    <Grid item xs={1} lg={1} md={1}>
                    <IconButton  aria-label="delete" color="primary" onClick={removeColumnItem.bind(null, i)}>
                        <Clear />
                      </IconButton>
                    </Grid>
                    </Grid>
                  ))}
                  <div className="d-block">
                    <Tooltip title="Add Item">
                      <IconButton aria-label="add" onClick={addNewColumnItem}>
                        <Add />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Fragment>
            </Grid>
            <Grid item xs={6} lg={6}>
            <div className="mb-2 mt-2">
            column value
            </div>
            <Fragment>
              {rowValues.map((e, i) => (
                <Grid container spacing={0} key={i}>
                <Grid item xs={11} lg={11} md={11}>
                <TextField type="text"  size="small"  multiline fullWidth={true} className="mb-1" variant="outlined" value={e} onChange={(event) => updateRowItem(event.target.value, i)} onBlur={submitDropDown} />
                </Grid>
                <Grid item xs={1} lg={1} md={1}>
                <IconButton  aria-label="delete" color="primary" onClick={removeRowItem.bind(null, i)}>
                    <Clear />
                  </IconButton>
                </Grid>
                </Grid>
              ))}
              <div className="d-block">
                <Tooltip title="Add Item">
                  <IconButton aria-label="add" onClick={addNewRowItem}>
                    <Add />
                  </IconButton>
                </Tooltip>
              </div>
            </Fragment>
            </Grid>
            
        </Grid>

        
        
      
    </Fragment>
  )
}

