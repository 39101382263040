import React, { useEffect } from 'react'
import {Fragment} from 'react';
import {
    FormControl,
    FormControlLabel,
    Button,
    ButtonGroup,
    Radio,
    RadioGroup,
    FormLabel
} from '@material-ui/core';

import { StarBorderOutlined, Favorite, SentimentSatisfiedAlt } from '@material-ui/icons';

export default (props) => {
  const values = props.questionObject.hasOwnProperty('icon') ? props.questionObject : {
    max: "5",
    icon: 'star'
  };
  const [max, setMax] = React.useState(values.max);
  const [icon, setIcon] = React.useState(values.icon);
  

  useEffect(() => {
    if (!props.questionObject.hasOwnProperty('icon')) {
      props.updateQuestionObject(values);
    }      
  },[]);

  const updateQuestionObject = (obj) => {
    props.updateQuestionObject(obj);
  }

  const handelSetMax = event => {
    let value = event.target.value;
    setMax(value);
    updateQuestionObject({ ...values, max: value })
  }

  const handelSetIcon = value =>{
    setIcon(value);
    updateQuestionObject({ ...values, icon: value })
  }

  return(
    <Fragment>
      <FormControl component="fieldset" className="d-block">
        <FormLabel component="legend">Select maximum rating</FormLabel>
        <RadioGroup aria-label="out of" name="out-of" value={max} onChange={handelSetMax} row >
          <FormControlLabel value="5" control={<Radio />} label="5" />
          <FormControlLabel value="10" control={<Radio />} label="10" />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" className="d-block">
        <FormLabel component="legend">Rating Icon</FormLabel>
        <ButtonGroup color="primary" aria-label="rating type icon select">
          <Button variant={icon == "star" ? "contained" : "outlined" } startIcon={<StarBorderOutlined />} onClick={handelSetIcon.bind(null, "star")}>Star</Button>
          <Button variant={icon == "heart" ? "contained" : "outlined" } startIcon={<Favorite />} onClick={handelSetIcon.bind(null, "heart")}>Heart</Button>
          <Button variant={icon == "smile" ? "contained" : "outlined" } startIcon={<SentimentSatisfiedAlt />} onClick={handelSetIcon.bind(null, "smile")}>Smile</Button>
        </ButtonGroup>
      </FormControl>
    </Fragment>
  )
}