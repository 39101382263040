import React, { useState ,Fragment} from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { CircularProgress} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import {
    Button,
    Slider,
    Typography,
    Grid,
} from '@material-ui/core';

const marks = [
    {
        value: 0,
        label: 'Strong Dislike',
    },
    {
        value: 25,
        label: 'Dislike',
    },
    {
        value: 50,
        label: 'Neutral',
    },
    {
        value: 75,
        label: 'Like',
    },
    {
        value: 100,
        label: 'Strong Like',
    }
];

export default (props) => {
    const [progressbar, setProgressbar] = useState(false);
    const [timeStampEmotions, setTimeStampEmotions] = useState([])
    let answerValue = {}
    try {
        answerValue = props.answer.value || {};
      }
      catch (err) {}

    
    const onChange = (event, newValue) => {
       
        if (props.videoRef.current) {
            let obj = {}
            let val = event.target.value
            obj[props.videoRef.current.currentTime] = newValue
            timeStampEmotions.push(obj)
        }
    };

    const submitAnswer = () => {
        setProgressbar(true)
        answerValue={value:{...timeStampEmotions}}
        props.submit(answerValue)


    }

    const resetSubmission = () => {
        setTimeStampEmotions([])

    }

    const onClick = () => {

    }
    return (
        <Fragment>
        <ThemeProvider theme={props.theme.pallet}>
        <div className="card-body justify-content-between">
            <Grid container spacing={1} >
                <Grid item xs={12} lg={12} >
                    <Grid container justify="center">
                        <Grid item>
                            <Typography id="discrete-slider-custom" gutterBottom>
                                Please record your reaction second by second
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} >
                <Grid item xs={1} lg={1} >
                    <Grid container justify="center">
                        <Grid item>
                            <ThumbUpIcon  color={props.theme.color}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10} lg={10} >
                    <Slider
                        defaultValue={50}
                        aria-labelledby="discrete-slider-custom"
                        step={25}
                        valueLabelDisplay="off"
                        marks={marks}
                        onChange={onChange}
                        color={props.theme.color}
                    />
                </Grid>
                <Grid item xs={1} lg={1} >
                    <Grid container justify="center">
                        <Grid item>
                            <ThumbDownIcon color={props.theme.color} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        <div className="d-block mt-3">
                {props.preview ? null : (
                <Button  variant={props.theme.buttonVariant} color={props.theme.color} onClick={submitAnswer}>Submit</Button>
                )}
                {props.preview ? null : (
                <Button variant={props.theme.buttonVariant} color={props.theme.color} onClick={resetSubmission} >Cancel</Button> 
                )}
                { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
            </div>
        </ThemeProvider>
        </Fragment>
    )
}
