import React, { Fragment, useEffect } from 'react';
import Project from './project'
import Tables from './table'
import Report from './report'
import {Survey, Authorization} from '../../Store/ApplicationStore'

import {
  Divider
} from '@material-ui/core';

export default (props) => {
  const [surveyData, setSurveyData] = React.useState([]);
  const [surveyFilterData, setSurveyFilterData] = React.useState([]);
  const [surveyActive, setSurveyActive] = React.useState(0);
  const [surveyCount, setSurveyCount] = React.useState(0);
  const [simpleSurveyCount, setSimpleSurveyCount] = React.useState(0);
  const [adTestCount, setAdTestCount] = React.useState(0);
  const [videoConferenceCount, setVideoConferenceCount] = React.useState(0);
  const [pageLength, setPageLength] = React.useState(0);
  const [surveyChunkData, setSurveyChunkData] = React.useState([]);


  useEffect(() => {
    if (surveyData.length === 0){
      GetSurveyList()
    }
  },[surveyData]);


  const GetSurveyList = async () =>{
    try {
        let result = await Survey.getAllSurveys();
        if (result.length){
            setSurveyData(result)
            splitData(result)
            GetSurveyCount(result)
        }
    }
    catch(err) {
      console.log(err);
    }
  }


  const GetSurveyFilter = (text) =>{
    const data = []
    if (text.length >= 3){
        surveyData.forEach((e) => {
          if(e.title.toLowerCase().includes(text))
          {
            data.push(e)
          } 
      })
      setSurveyFilterData(data)
    }else{
      setIntalPageData()
    }
    
  }

  const splitData = (data)=>{
    let out = chunkArray(data,10)
    setSurveyChunkData(out)
    setPageLength(out.length)
    setSurveyFilterData(out[0])
  }

  const setIntalPageData = () => {
    if (surveyChunkData.length)
      setSurveyFilterData(surveyChunkData[0])
  }

  const chunkArray = (myArray, chunk_size) =>{
    let index = 0;
    let arrayLength = myArray.length;
    let tempArray = [];
    
    for (index = 0; index < arrayLength; index += chunk_size) {
        let myChunk = myArray.slice(index, index+chunk_size);
        tempArray.push(myChunk);
    }
    return tempArray;
  }

  const GetSurveyCount = (data) =>{
    let count = 0
    let _simpleSurveyCount = 0
    let _adTestCount = 0
    let _videoConference = 0
    setSurveyCount(data.length)
    if (data.length)
    {
      data.forEach((e) => {
        if (e.status === 0){
          count++
        }
        if (e.surveyTypeId === 1){
          _simpleSurveyCount++
        }
        if (e.surveyTypeId === 2){
          _adTestCount++
        }
        if (e.surveyTypeId === 3){
          _videoConference++
        }
      })
      setSimpleSurveyCount(_simpleSurveyCount)
      setAdTestCount(_adTestCount)
      setVideoConferenceCount(_videoConference)
      setSurveyActive(count)
    }
  }

  const handlePageValue = (pageId) => {
    let count = pageId - 1
    setSurveyFilterData(surveyChunkData[count])
  }

  
  return (
    <Fragment>
      <Report active={surveyActive} count={surveyCount} SimpleSurveyCount={simpleSurveyCount} adCount={adTestCount} 
      videoConfCount={videoConferenceCount}/>
      <Project filter={GetSurveyFilter} handlePageValue={handlePageValue} pageLength={pageLength}/>
      <Tables data={surveyFilterData}/>
      <Divider/>
    </Fragment>
    
  )
}