import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Paper,
  Box
} from '@material-ui/core';

import { connect } from 'react-redux';
import { Save, DashboardTwoTone } from '@material-ui/icons';

function PageTitle(props) {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleIconBox,
    pageTitleDescription,
    pageTitleComponentRight
  } = props;

  return (
    <Fragment>
      <Paper
        square
        elevation={2}
        className={clsx('app-page-title', 'app-page-title-alt-1')}>
        <Box className="app-page-title--first">
          {pageTitleIconBox && (
            <Paper
              elevation={2}
              className="app-page-title--iconbox d-70 d-flex align-items-center bg-secondary justify-content-center">
              <DashboardTwoTone />
            </Paper>
          )}
          <div className="app-page-title--heading">
            <h1>{props.titleHeading}</h1>
            {pageTitleDescription && (
              <div className="app-page-title--description">
                {props.titleDescription}
              </div>
            )}
          </div>
        </Box>
        <div className="speedial-wrapper">
          {pageTitleComponentRight ? pageTitleComponentRight : null}
        </div>
      </Paper>
    </Fragment>
  );
}
const mapStateToProps = state => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(PageTitle);