import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Login from './login'
import Register from './register'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'date-fns';


import {
  Grid,
  Container,
  IconButton,
  Box,
  Typography,
  Tabs,
  Tab,
  Tooltip
} from '@material-ui/core';

// import hero8 from '../../../assets/images/hero-bg/hero-8.jpg';

import { withStyles } from '@material-ui/core/styles';


const RegisterLogin = (props) => {
  const [value, setValue] = React.useState(props.tab);

  const handleChange = (event, newValue) => {
     setValue(newValue);
  };
  const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      height: '6px',
      '& > div': {
        maxWidth: 40,
        height: '4px',
        borderRadius: '25px',
        width: '100%',
        backgroundColor: '#000'
      }
    }
  })(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);
  
  const StyledTab = withStyles(theme => ({
    root: {
      textTransform: 'none',
      color: theme.palette.primary[900],
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      '&:focus': {
        opacity: 1
      }
    }
  }))(props => <Tab disableRipple {...props} />);
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        {...other}>
        {value === index && <Box p={0}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  return (
    <Fragment>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={5}
                      className="d-flex align-items-center d-none">
                      <div className="w-100 d-none d-sm-block d-md-block">
                      <div className="hero-wrapper w-100 bg-composed-wrapper bg-plum-plate min-vh-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image"
                            style={{ backgroundImage: 'url(' + 'https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-8.jpg' + ')' }}
                          />
                          <div className="bg-composed-wrapper--bg bg-premium-dark opacity-5" />
                          <div className="bg-composed-wrapper--content p-5">
                            <div className="text-white mt-3">
                              <h1 className="display-4 my-3 font-weight-bold" style={{color:"white"}}>
                                More Than Just a Survey
                              </h1>
                              <p className="font-size-md mb-0 text-white-50">
                                ENUMERATE.AI
                              </p>
                              
                            </div>
                          </div>
                        </div>
                        <div className="hero-footer pb-4">
                          <Tooltip arrow title="Facebook">
                            <IconButton
                              color="inherit"
                              size="medium"
                              variant="outlined"
                              className="text-white-50">
                              <FontAwesomeIcon
                                icon={['fab', 'facebook']}
                                className="font-size-md"
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Twitter">
                            <IconButton
                              color="inherit"
                              size="medium"
                              variant="outlined"
                              className="text-white-50">
                              <FontAwesomeIcon
                                icon={['fab', 'twitter']}
                                className="font-size-md"
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Google">
                            <IconButton
                              color="inherit"
                              size="medium"
                              variant="outlined"
                              className="text-white-50">
                              <FontAwesomeIcon
                                icon={['fab', 'google']}
                                className="font-size-md"
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Instagram">
                            <IconButton
                              color="inherit"
                              size="medium"
                              variant="outlined"
                              className="text-white-50">
                              <FontAwesomeIcon
                                icon={['fab', 'instagram']}
                                className="font-size-md"
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      lg={7}
                      className="d-flex align-items-center">
                      <Container maxWidth="sm">
                        <div className="pt-5 pb-4">
                          <StyledTabs
                            value={value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChange}>
                            <StyledTab label="Sign in" />
                            <StyledTab label="Create an account" />
                          </StyledTabs>
                        </div>
                        <TabPanel value={value} index={0}>
                          <Login login={props.login} isValidLogin={props.isValidLogin}/>
                        </TabPanel>
                        <TabPanel value={value} index={1}>

                          <Register register={props.register} isValidLogin={props.isValidLogins}/>
                        </TabPanel>
                       </Container>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterLogin;
