
import React, { Fragment } from "react";
import "antd/dist/antd.css";
import { ThemeProvider } from '@material-ui/core/styles';
import { Table } from "antd";
import { Button, CircularProgress, } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// import './matrix.css'
export default class MatrixCreate extends React.Component {
  constructor(props) {
    super(props);
    let _rowValue = props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('row') ? props.questionObject.options.row : [] :[]
    let _columnValue = props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('column') ? props.questionObject.options.column : [] : []
    let _titleValue = props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('title') ? props.questionObject.options.title : "" :""
    let _typeValue = props.questionObject.hasOwnProperty('options') ? props.questionObject.options.hasOwnProperty('type') ? props.questionObject.options.type : false : false
    this.state = {
      isAnswered: false,
      progressbar: false,
      type: _typeValue,
      task: { 
          row: _rowValue ,
          column:  _columnValue ,
          title: _titleValue != "" ? _titleValue : ""},
      selected: {}
    };
  }

  onRadioChange = e => {
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    this.setState({
      ...this.state,
      selected: { ...this.state.selected, [name]: value }
    });
  };

  onCheckBoxChange = e => {
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    let data = this.state.selected[name] != null ? this.state.selected[name] : []
    let val = []
    if (data.indexOf(value) !== -1){
      val = data.pop(value)
    }else{
      val = data.concat([value])
    }
    let selectedData = {...this.state.selected}
    selectedData[name] = val.sort()
    this.setState({
      selected: selectedData
    });
  };

  onRadioChangeClick = (e, name ,value) => {
    e.preventDefault()
    this.state.selected[name] = value
    this.setState({
      ...this.state,
      selected: { ...this.state.selected, name: value }
    });
  };



  submitAnswer = () => {
  if ( Object.keys(this.state.selected).length !== this.state.task.column.length){
    this.setState({
        isAnswered: true
    })
  }
  else{
    this.setState({
      progressbar: true,
      ...this.state,
      isAnswered: false,
      selected: {}
    });
    this.props.submit({
        value:  this.state.selected 
    })
  }
  }
  
  render() {
    let columns = [];
    columns.push({
      title: this.state.task.title ?? "",
      dataIndex: "name",
      key: "name",
      width: "28vw",
      fixed: 'left'
    });

    this.state.task.row.forEach((option, i) => {
      columns.push({
        title: option.trim(),
        key: option,
        
        render: row => {
          if (this.state.type === false){
            return (
              // <div style={{cursor: 'pointer'} } onClick={(e)=>{this.onRadioChangeClick(e,row.name,option)}, {this.state.selected[row.name] == option}}>
              <input 
                key={i}
                type="radio"
                checked={this.state.selected[row.name] == option}
                onChange={this.onRadioChange}
                name={row.name}
                value={option}
                style={{cursor: 'pointer'} }
              />
              // </div>
            );
          }else{
          return (
            // <div style={{cursor: 'pointer'} } onClick={(e)=>{this.onRadioChangeClick(e,row.name,option)}, {this.state.selected[row.name] == option}}>
            <input 
              key={i}
              type="checkbox"
              onChange={this.onCheckBoxChange}
              name={row.name}
              value={option}
              style={{cursor: 'pointer'} }
            />
            // </div>
          );
        }
        }
      });
    });

    let rowHeaders = [];
    this.state.task.column.forEach((target, i) => {
      rowHeaders.push({ name: target.trim(),key: i});
    });

    const getFontSize = (fontSize) =>{
      if (window.innerWidth < 480){
        return "12px"
      }
      if (fontSize === "13px"){
        return "12px"
      }else {
        return "14px"
  
      }
    }
    return (
      <Fragment>
        <ThemeProvider theme={this.props.theme.pallet}>
      <div style={{fontSize:"100px !important"}}>
        <Table
        style={{overflowY:"scroll"}}
          scroll={{ x: 'calc(500px + 50%)' , y : (rowHeaders.length * 52) }}
          columns={columns}
          dataSource={rowHeaders}
          size="middle"
          bordered
          pagination={false}
        />
      </div>
          {this.state.isAnswered ? <div>
                <Alert severity="error">Please Answer All the Questions</Alert>
            </div> : null}
      <div className="d-block mt-2">
          {this.props.preview ? null : (
              <Button className={this.file ? "button save m-1" : "button save Disable m-1"} disabled={this.state.progressbar} variant={this.props.theme.buttonVariant} color={this.props.theme.color}  onClick={this.submitAnswer}>Submit</Button>
          )}
            { this.state.progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
      </div>
          <React.Fragment>
          <style>
            {`
            .ant-table-cell {
              font-size: ${getFontSize(this.props.theme.componentFontSize) };
              font-family: ${this.props.theme.fontFamily};
              color: ${this.props.theme.fontColor};
            }
            `}
          </style>
    </React.Fragment>

      </ThemeProvider>
    </Fragment>
    );
  }
}
