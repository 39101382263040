
import React, { Fragment } from 'react';
import {
    Button,
    Menu,
    Grid
  } from '@material-ui/core';

import IosConstructOutline from 'react-ionicons/lib/IosConstructOutline';
import IosTrashOutline from 'react-ionicons/lib/IosTrashOutline';
import IosCopyOutline from 'react-ionicons/lib/IosCopyOutline';
import IosMoreOutline from 'react-ionicons/lib/IosMoreOutline'
import MdPodium from 'react-ionicons/lib/MdPodium'
import { Survey } from '../../Store/ApplicationStore'
import { useHistory } from 'react-router-dom';


export default function DropDown(props) {

    const [anchorElMenu1, setAnchorElMenu1] = React.useState(null);
    const handleClickMenu1 = event => {setAnchorElMenu1(event.currentTarget);};
    const handleCloseMenu1 = () => {setAnchorElMenu1(null);};
    const history = useHistory();

    const handleEdit = (id)=>{
        history.push({
            pathname: '/Manage',
            search: '?id='+ props.id 
         });
    }

    const handleAnalytics = (id)=>{
        history.push({
            pathname: '/Dashboard',
            search: '?id='+ props.id 
         });
    }

    const handleDuplicate = async (id)=>{
        const data ={
            batchId:id
        }
        await Survey.copySurvey(data).then(function(res){
            history.push({
                pathname: '/Manage',
                search: '?id='+ res.survey_id 
                });
            }
        )
       }
    
    const handleDelete = (id)=>{
        const data ={
            batchId:id
        }
        Survey.deleteExistingSurvey(data).then(function(e){
            if (e.status == 'success'){
                window.location.reload();
            }
        })
        
    }
    return (
        <Fragment>
            <div className="text-center">
                <Button variant="outlined" color="primary" onClick={handleClickMenu1}>
               < IosMoreOutline fontSize="28px" color="var(--primary)"/>
            </Button>
            </div>
            <Menu
            anchorEl={anchorElMenu1}
            keepMounted
            open={Boolean(anchorElMenu1)}
            onClose={handleCloseMenu1} classes={{ list: 'p-0' }}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
                <div className="overflow-hidden dropdown-menu-lg p-0">
                    <div className="grid-menu grid-menu-2col">
                        <Grid container spacing={0}>
                            <Grid item sm={6} className="p-2">
                                <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => { handleEdit(props.id)}}>
                                    <span className="font-size-xxl d-block mb-1"><IosConstructOutline fontSize="28px" color="var(--primary)"/></span>
                                    <span className="pb-1">Edit</span>
                                </Button>
                            </Grid>
                            <Grid item sm={6} className="p-2">
                                <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => {handleAnalytics(props.id)}}>
                                    <span className="font-size-xxl d-block mb-1"><MdPodium fontSize="28px" color="var(--info)"/></span>
                                    <span className="pb-1">Analytics</span>
                                </Button>
                            </Grid>
                            <Grid item sm={6} className="p-2">
                                <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => {handleDuplicate(props.id)}}>
                                    <span className="font-size-xxl d-block mb-1"><IosCopyOutline fontSize="28px" color="var(--success)" /></span>
                                    <span className="pb-1">Duplicate</span>
                                </Button>
                            </Grid>
                            <Grid item sm={6} className="p-2">
                                <Button variant="outlined" color="primary" className="border-0 w-100 d-block" onClick={(event) => {handleDelete(props.id)}}>
                                    <span className="font-size-xxl d-block mb-1">< IosTrashOutline fontSize="28px" color="var(--danger)"/></span>
                                    <span className="pb-1">Delete</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Menu>
        </Fragment>
    )


}