import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Rating from '@material-ui/lab/Rating';
import { CircularProgress} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Favorite, StarBorder } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
export default (props) =>{
  const [rating, setRating] = useState(props.answer.value || 2);
  const [progressbar, setProgressbar] = useState(false);
  const StyledRating = withStyles({
    iconFilled: {
      color: '#ff6d75',
    },
    iconHover: {
      color: '#ff3d47',
    },
  })(Rating);
  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon fontSize="large" />,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon fontSize="large" />,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon fontSize="large" />,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon fontSize="large" />,
      label: 'Satisfied',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon fontSize="large" />,
      label: 'Very Satisfied',
    },
  };

  const maxValue = props.questionObject.max || 5;
  const icon = props.questionObject.icon;

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[maxValue > 5 ? Math.floor((value+1)/2) : value].icon}</span>;
  }
  
  IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
  };

  const RatingIcon = (props) => {
    if (icon === 'smile') {
      return (
        <Rating
          {...props}
          getLabelText={value => customIcons[maxValue > 5 ? Math.floor((value+1)/2) : value].label}
          IconContainerComponent={IconContainer}
        />
      )
    }
    else if (icon === 'star') {
      return (
        <Rating
          {...props}
          emptyIcon={<StarBorder fontSize="large" />}
        />
      )
    }
    else if (icon === 'heart') {
      return (
        <StyledRating
          {...props}
          icon={<Favorite fontSize="large" />}
        />
      )
    }
  }

  const selectRating = (event, newValue) => {
    setRating(newValue);
    if (!props.preview) {
      props.save({
        value: newValue
      })
    }
  }

  const submitRating = () => {
    setProgressbar(true)
    props.submit({
      value: parseFloat(rating)
    })
  }

  return (
    <Fragment>
      <ThemeProvider theme={props.theme.pallet}>
        <RatingIcon max={parseInt(props.questionObject.max)} name="rating" size="large" value={rating} onChange={selectRating} />
        <div className="d-block">
          {props.preview ? null : (<Button className="mt-3" color={props.theme.color} variant={props.theme.buttonVariant} onClick={submitRating}>Submit</Button>)}
          { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
        </div>
      </ThemeProvider>
    </Fragment>
  );
}