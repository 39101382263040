import React, { Component, Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tab, Box, Typography, Tabs, Grid, AppBar, List, ListItem, 
    CircularProgress, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import StopIcon from '@material-ui/icons/Stop';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ThemeProvider } from '@material-ui/core/styles';
import { TurnedInRounded } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
class AudioCreate extends Component {
    render() {
        return (
            <Typography> User can answer through a microphone or can upload a file. </Typography>
        )
    }

}

const AudioRender = (props) => {
    const [value, setValue] = useState(0);
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const iOS = ()=> {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      }
      
    const hasUserMedia = () => { 
        //check if the browser supports the WebRTC 
        return !!(navigator.getUserMedia || navigator.webkitGetUserMedia || 
           navigator.mozGetUserMedia); 
     }

    return (
        (
            (iOS()) ? 
                    <div>
                        <Typography>Please Share a Audio file</Typography>
                        <FileUpload {...props} />
                    </div>
                    :
                    (!hasUserMedia() ? 
                    <div>
                        <Typography>Please Share a Audio file</Typography>
                        <FileUpload {...props} />
                    </div>
                    :
                    <div>
                        <ThemeProvider theme={props.theme.pallet}>
                            <AppBar position="static" color={props.theme.color}>
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                    <Tab label="Record Audio" {...a11yProps(0)} />
                                    <Tab label="Upload File" {...a11yProps(1)} />

                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <AudioRecordWidget  {...props} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <FileUpload  {...props} />
                            </TabPanel>
                    </ThemeProvider>
                    </div>
            )
        )
    );

}

const FileUpload = (props) => {
    const [progressbar, setProgressbar] = useState(false);
    const [isAnswered,setIsAnswered] = useState(false)
    let fileUpload = useRef();
    const [file, setFile] = useState([]);
    const openFileLoader = () => {
        fileUpload.current.click();
    }
    const uploadFiles = (event) => {
        if (fileUpload.current.files.length > 0 && fileUpload.current.files[0].name) {
            let e = fileUpload.current.files[0];
            let fileName = e.name.replace(/\s+/g,"_");
            let newFile = new File([e], fileName, { type: e.type });
            setFile([newFile]);
        }
    }
    const removeFile = () => {
        setFile([]);
    }

    const submitFile = () => {
        if (file.length === 0 )
        {
            setIsAnswered(true)
        }
        else{
            setIsAnswered(false)
            setProgressbar(true)

            props.submit({
                files: file
            });
        }       
    }

    return (
        <Fragment>
            <List>
                {file.map((e, i) => (
                    <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <DescriptionIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={e.name}
                    />
                    <ListItemSecondaryAction>
                        <IconButton color={props.theme.color} edge="end" aria-label="delete" onClick={removeFile}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <Button size="large" variant={props.theme.buttonVariant} color={props.theme.color} component="label" endIcon={<CloudUploadIcon />} onClick={openFileLoader} >
               Share Audio
            </Button>
            <input ref={fileUpload} type="file" accept="audio/*" className="d-none" onChange={uploadFiles} />
            {isAnswered ? <div>
          <Alert severity="error">Please Answer </Alert>
          </div> : null}
            <div className="d-block pt-4">
                <Button color={props.theme.color} variant={props.theme.buttonVariant} onClick={submitFile} disabled={file.length == 0} >Submit</Button>
                { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
            </div>

        </Fragment>
    )
}

class AudioRecordWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stream: "",
            source: "",
            constraint: {
                audio: true
            },
            recording: false,
            review: false,
            webcamReview: false,
            mimeType: "",
            audioInputs: [],
            progressbar: false,
            isAnswered :false
        }
        this.selectSource = this.selectSource.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.startRecording = this.startRecording.bind(this);
        this.selectFiles = this.selectFiles.bind(this);
        this.submit = this.submit.bind(this);
        this.resetSubmission = this.resetSubmission.bind(this);
        this.preview = this.preview.bind(this);
        this.webcam = React.createRef(null);
        this.uploadFile = React.createRef(null);
        this.stream = null;
        this.recording = null;
        this.data = [];
        this.file = null;
        
    }

    async componentDidMount() {
        let options = { mimeType: 'audio/webm' };

        let inputDevices = [];
        try {
            if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                inputDevices = await navigator.mediaDevices.enumerateDevices();
            }
        }
        catch (err) { }
        let audioDevices = [];
        inputDevices.forEach((e) => {

            if (e.kind == "audioinput") {
                audioDevices.push(e);
            }
        })
        this.setState({
            mimeType: options,
   
            audioInputs: audioDevices
        })

        this.selectSource('webcam')
    }



    async selectSource(source) {
        if (source == "webcam") {
            if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
                try {
                    let stream = await navigator.mediaDevices.getUserMedia(this.state.constraint);
                    this.webcam.current.srcObject = stream;
                    this.stream = stream;
                }
                catch (err) {
                }
            }
        }
        else {
            if (this.stream) {
                let tracks = this.stream.getTracks();
                tracks.forEach(function (track) {
                    track.stop();
                });
                this.webcam.current.srcObject = null;
                this.stream = null;
            }
            this.uploadFile.current.click();
        }
        this.setState({
            source: source == "webcam" ? "webcam" : this.state.source,
            review: false
        })
    }

    async startRecording() {
        if (this.webcam.current.srcObject === null) {
            try {
                await this.selectSource('webcam');
            }
            catch (err) { }
        }

        this.file = null;
        this.recording = new MediaRecorder(this.stream);
        this.data = [];
        this.recording.ondataavailable = (event) => {
            this.data.push(event.data);
        };
        this.recording.start();
        this.setState({
            recording: true,
            review: false,
            webcamReview: false
        })
    }

    stopRecording() {
        this.recording.onstop = (event) => {
            this.file = new Blob(this.data, { type: 'audio/webm' })
        }
        this.recording.stop();
        this.setState({
            recording: false,
            webcamReview: true
        })
    }

    preview() {
        if (this.stream) {
            let tracks = this.stream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
            this.webcam.current.srcObject = null;
            this.stream = null;
            if (this.file !== null){
                this.webcam.current.children[0].src = URL.createObjectURL(this.file);
                this.webcam.current.load();
            }
        }
        this.setState({
            review: true,
            webcamReview: false
        })
    }

    selectFiles() {
        if (this.uploadFile.current.files.length > 0 && this.uploadFile.current.files[0].name) {
            this.file = new File([this.uploadFile.current.files[0]], this.uploadFile.current.files[0].name, { type: this.uploadFile.current.files[0].type });
            this.webcam.current.children[0].src = URL.createObjectURL(this.file);
            this.webcam.current.load();
            this.setState({
                source: "file",
                review: true
            });
        }
        else {
            this.resetSubmission();
        }
    }

    submit() {
        if (this.file.length === 0){
            this.setState({
                isAnswered: true
            })
        }
        else{
            this.setState({
                review: false,
                source: "",
                progressbar: true,
                isAnswered: false
            });
            this.setState({
                submit: { files : this.file }
            });
        }
    }

    resetSubmission() {
        if (this.stream) {
            let tracks = this.stream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
            this.webcam.current.srcObject = null;
            this.stream = null;
        }
        this.setState({
            recording: false,
            review: false,
            source: ""
        });
        this.file = null;
    }

    render() {
        return (
            <Fragment>
                <div className="card-body justify-content-between">
                    <Grid container spacing={1} >
                        <Grid item xs={12} lg={12} >
                            <Grid container justify="center">
                                <Grid item>
                                <audio className="videoContainer" autoPlay={!this.state.review} ref={this.webcam} muted={!this.state.review} controls={this.state.review} controlsList="nodownload" >
                                    <source />
                                </audio>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} >
                            <Grid container justify="center">
                                <Grid item>
                                {this.state.recording ? (
                                <LinearProgress className="videoContainer" color={this.props.theme.color} />
                               
                            ) : (<div></div>)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} >
                            <Grid container justify="center">
                                <Grid item>
                                {this.state.recording ? (
                                <LinearProgress className="videoContainer" color={this.props.theme.color} />
                               
                            ) : (<div></div>)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} >
                            <Grid container justify="center" >
                                <Grid item >
                                    <div className="Action">
                                        {this.state.recording ? (
                                            <Button variant={this.props.theme.buttonVariant} color={this.props.theme.color} className="Action"  startIcon={<StopIcon />} onClick={this.stopRecording}>Stop Recording</Button>
                                        ) : (
                                                <Button variant={this.props.theme.buttonVariant} color={this.props.theme.color} className="Action"  startIcon={<PlayArrowIcon />} onClick={this.startRecording}>Start Recording</Button>
                                            )}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={this.state.webcamReview ? "Action" : "Action Disable"} onClick={this.preview}>
                                        <Button variant={this.props.theme.buttonVariant} color={this.state.started? "default": this.props.theme.color} startIcon={<VisibilityIcon />}>Preview</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            </div>
            {this.state.isAnswered ? <div>
                <Alert severity="error">Please Answer </Alert>
            </div> : null}
            <div className="d-block mt-3">
                {this.props.preview ? null : (
                <Button  className={this.file ? "button save m-1" : "button save Disable m-1"} variant={this.props.theme.buttonVariant} color={this.props.theme.color} onClick={this.submit}>Submit</Button>
                )}
                {this.props.preview ? null : (<Button variant={this.props.theme.buttonVariant} color={this.props.theme.color} className="button cancel m-1" onClick={this.resetSubmission} >Cancel</Button> 
                )}
                { this.state.progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
            </div>
        </Fragment>
        )
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AudioValidate = [];

export { AudioCreate, AudioRender, AudioValidate }