import React from 'react';
import ReactDOM from 'react-dom';
import CreationApp from './CreationApp';
import SubmissionApp from './SubmissionApp';
import * as serviceWorker from './serviceWorker';

const App = process.env.REACT_APP_SURVEY_TYPE == "submit" ? SubmissionApp : CreationApp;

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
