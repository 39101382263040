import React, { Fragment, useState } from 'react';
import { TextField, MenuItem, Button  ,CircularProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
export default (props) => {
  const useStyles = makeStyles({
    input: {
      backgroundColor:props.theme.componentBgColor , 
      opacity:props.theme.componentOpacity,
      fontSize:props.theme.componentFontSize, 
      color:props.theme.fontColor,
      fontFamily:props.theme.fontFamily
    },
  });
  const classes = useStyles();
  const list = props.questionObject.options || [];
  const [value, setValue] = useState([]);
  const [isAnswered,setIsAnswered] = useState(false)
  const [progressbar, setProgressbar] = useState(false);
  const handleChange = (event) => {
    setValue([event.target.value]);
    if (!props.preview) {
      props.save({
        value: event.target.value
      })
    }
  }

  const submitRating = () => {
    if (value.length === 0 )
    {
      setIsAnswered(true)
    }
    else{
      setIsAnswered(false)
      setProgressbar(true)
      props.submit({
        value: value
      })
    }
  }

  return (
    <Fragment>
      <ThemeProvider theme={props.theme.pallet}>
        <TextField
            select
            label="Select"
            value={value[0]}
            onChange={handleChange}
            helperText="Please select your value"
            fullWidth
            variant={props.theme.conponentVarient}
            color={props.theme.color}
            InputProps={{
              className: classes.input
            }}
            SelectProps={{ MenuProps: { PaperProps: { style : { opacity:props.theme.componentOpacity, backgroundColor:props.theme.componentBgColor } }} }} 
          >
            {list.map((option) => (
              <MenuItem key={option} value={option} 
              style={{
              fontSize:props.theme.componentFontSize, color:props.theme.fontColor, fontFamily:props.theme.fontFamily}} >
                {option}
              </MenuItem>
            ))}
          </TextField>
          {isAnswered ? <div>
          <Alert severity="error">Please Answer </Alert>
          </div> : null}
          <div className="d-block mt-3">
            {props.preview ? null : (<Button className="mt-3" color={props.theme.color} variant={props.theme.buttonVariant} onClick={submitRating}>Submit</Button>)}
            { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
          </div>
      </ThemeProvider>
    </Fragment>
  )
}