import React, { useReducer, useState, Fragment } from 'react';
import { Button, IconButton, ListItem,Dialog, DialogTitle, DialogContent,
  DialogActions, CardContent,
   Divider ,TextField, Grid,   Box,Card,
   Tooltip,} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { QuestionRender } from '../../../../components/Questions';
import questionList from '../../../../components/Questions/QuestionBank';
import { Refresh } from '@material-ui/icons';
import {PreviewTheme} from'../../../../theme/preview';
import { ThemeProvider } from '@material-ui/core/styles';
import {SketchPicker , SwatchesPicker} from 'react-color';
import TabPanel from '../../../../components/TabPanel';
import { Authorization } from '../../../../Store/ApplicationStore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import PageContainer from './page'
import BgImageDialog from './bgImageDialog'
import PageDialog from './pageDialog'
import LogoDialog from './logoDialog'
import '../Manage.css'
import { 
  List,
  Brush, 
} from '@material-ui/icons';

import '../Manage.css'
  import { 
    MenuItem,
    FormControl,
    InputLabel,
    Select,
  } from '@material-ui/core';
import { Survey } from 'Store/ApplicationStore';
const reducer = (state, action) => {
  let newQuestions = [];
  switch (action.type) {
    case 'add_questions':
      return {
        ...state, questions: action.data
      }
    case 'select_question':
      return {
        ...state, selected: action.index
      }
    case 'save_answer':
      newQuestions = [ ...state.questions ];
      newQuestions[state.selected] = { ...state.questions[state.selected], answer: { ...action.data }, status: 'SAVE' };
      return {
        ...state, questions: newQuestions
      }
    case 'submit_answer':
      newQuestions = [ ...state.questions ];
      newQuestions[action.index] = { ...state.questions[action.index], answer: { ...action.data }, status: 'SUBMIT' };
      return {
        ...state, questions: newQuestions
      }
    case 'submit_progress':
      newQuestions = [ ...state.questions ];
      newQuestions[state.selected] = { ...state.questions[state.selected], status: 'PROGRESS' };
      return {
        ...state, questions: newQuestions
      }
    case 'start_over':
      newQuestions = [ ...state.questions ];
      newQuestions.forEach((e) => {
        e.answer = {};
        e.status = 'start'
      });
      return {
        ...state, questions: newQuestions, selected: 0
      }
    case 'change_state':
      return {
        ...state, state: action.data
      }
  }
}

const ViewSurvey = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    selected: 0,
    questions: [ ...props.questions ],
    state: 'start'
  });
  const [skip, setSkip] = useState(false);
  const [openBgDialog, setOpenBGDialog] = React.useState(false);
  const [openPageDialog, setOpenPageDialog] = React.useState(false);
  const [openLogoDialog, setOpenLogoDialog] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleBackGroundImage = scrollType => () => {
    setOpenBGDialog(true);
    setScroll(scrollType);
  };

  const handleBGDialigClose = () => {
    setOpenBGDialog(false);
  };

  const handlePageDialogOpen = scrollType => () => {
    setOpenPageDialog(true);
    setScroll(scrollType);
  };

  const handlePageDialigClose = () => {
    setOpenPageDialog(false);
  };
  
  const handleLogoDialogOpen = scrollType => () => {
    setOpenLogoDialog(true);
    setScroll(scrollType);
  };

  const handleLogoDialigClose = () => {
    setOpenLogoDialog(false);
  };

  const [logo, setLogo] = useState( ( props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('logo'))?props.surveyData.theme.logo: "")
  const [logoWidth, setLogoWidth] = useState( ( props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('logoWidth'))?props.surveyData.theme.logoWidth: 200)
  const [logoHeight, setLogoHeight] = useState( ( props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('logoHeight'))?props.surveyData.theme.logoHeight: 50)
  const [fontFamily, setFontFamily] = useState( ( props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('fontFamily'))?props.surveyData.theme.fontFamily: "1")
  const [componentFontSize, setComponentFontSize] = useState( ( props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('componentFontSize'))?props.surveyData.theme.componentFontSize: "14px")
  const [fontColor, setFontColor] = useState( ( props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('fontColor'))?props.surveyData.theme.fontColor: "#000000")
  const [backgroundImage, setBackgroundImage] = useState( ( props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('backgroundImage'))?props.surveyData.theme.backgroundImage: "None")
  const [selectColor, setSelectColor] = useState( ( props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('color'))?props.surveyData.theme.color: "#4791db")
  const [bgSelectColor, setBGSelectColor] = useState( ( props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('backgroundColor'))?props.surveyData.theme.backgroundColor: "#ffffff50")
  const [componentBGColor, setComponentBGColor] = useState( ( props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('componentBgColor'))?props.surveyData.theme.componentBgColor: "#ffffff70")
  const [buttonVariant, setButtonVariant] = useState((props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('buttonVariant'))?props.surveyData.theme.buttonVariant: "outlined")
  const [componentVariant, setComponentVariant] = useState((props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('componentVariant')) ? props.surveyData.theme.componentVariant: "outlined")
  const [componentOpacity, setComponentOpacity] = useState((props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('componentOpacity')) ? props.surveyData.theme.componentOpacity: "0.9")
  const [questionFontSize, setQuestionFontSize] = useState((props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('questionFontSize')) ? props.surveyData.theme.questionFontSize: "18px")
  const [welcomePageTitle, setWelcomePageTitle] = useState((props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('welcomePageTitle')) ? props.surveyData.theme.welcomePageTitle:  "")
  const [welcomePageText, setWelcomePageText] = useState((props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('welcomePageText')) ? props.surveyData.theme.welcomePageText:  "")
  const [welcomePageSubText, setWelcomePageSubText] = useState((props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('welcomePageSubText')) ? props.surveyData.theme.welcomePageSubText:  "")
  const [showWelcomePage, setShowWelcomePage] = useState(true)
  const [thankYouPage, setThankYouPage] = useState((props.surveyData.theme !=null && props.surveyData.theme.hasOwnProperty('ThankYouPage')) ? props.surveyData.theme.ThankYouPage:  "Thank you for taking the time to complete this survey. We truly value the information you have provided.")
  const [theme, setTheme] = useState(PreviewTheme(selectColor , buttonVariant, componentVariant,bgSelectColor, componentBGColor, componentOpacity,
     questionFontSize, backgroundImage , componentFontSize,fontColor,fontFamily))
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [displayComponentBGColorPicker, setDisplayComponentBGColorPicker] = useState(false)
  const [displayBGColorPicker, setDisplayBGColorPicker] = useState(false)
  const [displayFontColorPicker , setDisplayFontColorPicker] = useState(false)
  const [city, setCity] = useState("")
  const [countryList, setCountryList] = React.useState([]);
  const [country, setCountry] = React.useState('');
  const [cityList, setCityList] = React.useState([]);
  const [anonymousUserRequiredFields, setAnonymousUserRequiredFields] = useState(props.surveyData.getDemographic != null ? props.surveyData.getDemographic :{age: false,
    gender: false,
    city: false,
    name:false,
    email:false,
    phone: false});
  
  const selectQuestion = (index) => {
    dispatch({ type: 'select_question', index: index })
  }

  const submitAnswer = async (answer) => {
    let index = state.selected;
    let nextIndex = skip ? validateConditionAndReturnNextQuestion(state.questions[state.selected]) : state.selected + 1;
    if (nextIndex >= state.questions.length) {
      dispatch({ type: 'change_state', data: 'end' });
    }
    else {
      selectQuestion(nextIndex);
    }
    dispatch({ type: 'submit_answer', data: answer, index: index });
  }

  const validateConditionAndReturnNextQuestion = (question) => {
    let condition = question.condition;
    let nextIndex = state.selected + 1;
    if (!condition.hasOwnProperty('list')) {
      return nextIndex;
    }
    for(let i = 0; i < condition.list.length; i++) {
      let tempFeQId = condition.list[i].jump;
      let conditionFlag = false;
      let prevCondition = 'or';
      for(let j = 0; j < condition.list[i].list.length; j++) {
        if (condition.list[i].list[j].hasOwnProperty('logic')) {
          prevCondition = condition.list[i].list[j].logic;
        }
        else {
          let currValidateQ = findQuestionByFeQId(condition.list[i].list[j].question);
          let validateValue = false;
          if (currValidateQ !== null) {
            let answer = state.questions[currValidateQ].answer;
            let conditionList = questionList[state.questions[currValidateQ].answerType].condition;
            for (let c = 0; c < conditionList.length; c++) {
              if (conditionList[c].id == condition.list[i].list[j].condition) {
                validateValue = conditionList[c].validate(answer, condition.list[i].list[j].value);
                break;
              }
            }
          }
          if (prevCondition == 'or') {
            conditionFlag = conditionFlag || validateValue;
          }
          else if (prevCondition == 'and') {
            conditionFlag = conditionFlag && validateValue;
          }
        }
      }
      if (conditionFlag) {
        return findQuestionByFeQId(tempFeQId);
      }
    }
    return nextIndex;
  }

  const findQuestionByFeQId = (feId) => {
    for(let i = 0; i < state.questions.length; i++) {
      if (state.questions[i].feQId == feId) {
        return i;
      }
    }
    return state.questions.length;
  }

  const saveAnswer = (answer) => {
    dispatch({ type: 'save_answer', data: answer })
  }

  const startOver = () => {
    dispatch({ type: 'start_over' });
  }

  const changeSkipVerification = () => {
    setSkip(!skip);
    startOver();
  }

  const startSurvey = () => {
    dispatch({ type: 'change_state', data: 'questions' });
  }

  const viewSelecedQuestion = (index) => {
    setShowWelcomePage(false)
    setSkip(false);
    startSurvey();
    dispatch({ type: 'select_question', index: index });
  }
  //******************style********************//

  const surveyTheme = ()=>{
    return {
      color: selectColor,
      buttonVariant: buttonVariant,
      componentVariant:componentVariant,
      backgroundColor:bgSelectColor,
      componentBgColor:componentBGColor,
      componentOpacity:componentOpacity,
      questionFontSize:questionFontSize,
      backgroundImage:backgroundImage,
      componentFontSize:componentFontSize,
      fontColor:fontColor,
      fontFamily:fontFamily,
      welcomePageTitle:welcomePageTitle,
      welcomePageText: welcomePageText,
      welcomePageSubText:welcomePageSubText,
      thankYouPage:thankYouPage,
      logo:logo,
      logoWidth:logoWidth,
      logoHeight:logoHeight
    }
  }

  const handleSavePageData = () =>{
    handlePageDialigClose()
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }
  const handleLogoSave = (url, width, Height) =>{
    handleLogoDialigClose()
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    _surveyTheme.logo = url
    _surveyTheme.logoWidth = width
    _surveyTheme.logoHeight = Height
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }


  const handleColorChange = (color, event ) =>{
    setSelectColor(color.hex)
    let _theme = PreviewTheme(color.hex, buttonVariant, componentVariant,
       bgSelectColor, componentBGColor,  componentOpacity, questionFontSize, backgroundImage,
       componentFontSize,fontColor,fontFamily)
    setTheme(_theme)
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    _surveyTheme.color = color.hex
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }
  
  const handleBGColorChange = (color, event ) =>{
    setBGSelectColor(color.hex)
    setBackgroundImage("None")
    let _theme = PreviewTheme(selectColor, buttonVariant, componentVariant, 
      color.hex, componentBGColor, componentOpacity, questionFontSize, "None",
      componentFontSize,fontColor,fontFamily)
    setTheme(_theme)
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    _surveyTheme.backgroundColor = color.rgb
    _surveyTheme.backgroundColor = "None"
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }
  const handleComponentBGColorChange = (color, event ) =>{
    setComponentBGColor(color.rgb)
    let _theme = PreviewTheme(selectColor, buttonVariant, componentVariant, 
      bgSelectColor, color.rgb, componentOpacity, questionFontSize, backgroundImage,
      componentFontSize,fontColor,fontFamily)
    setTheme(_theme)
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    _surveyTheme.componentBgColor = color.rgb
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }

  const handleFontColorChange = (color, event ) =>{
    setFontColor(color.hex)
    let _theme = PreviewTheme(selectColor, buttonVariant, componentVariant, 
      bgSelectColor, componentBGColor, componentOpacity, questionFontSize, backgroundImage,
      componentFontSize, color.hex, fontFamily)
    setTheme(_theme)
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    _surveyTheme.fontColor = color.hex
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }

  const handleButtonVariantChange = (event, value) =>{
    setButtonVariant(value.props.value)
    let _theme = PreviewTheme(selectColor, value.props.value, componentVariant,
      bgSelectColor, componentBGColor,  componentOpacity, questionFontSize, backgroundImage,
      componentFontSize,fontColor,fontFamily)
    setTheme(_theme)
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    _surveyTheme.buttonVariant = value.props.value
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }

  const handleComponentVariantChange = (event, value) =>{
    setComponentVariant(value.props.value)
    let _theme = PreviewTheme(selectColor, buttonVariant, value.props.value, 
      bgSelectColor, componentBGColor, componentOpacity, questionFontSize, backgroundImage,
      componentFontSize,fontColor,fontFamily)
    setTheme(_theme)
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    _surveyTheme.componentVariant = value.props.value
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }



  const handleComponentOpacity = (event, value) =>{
    setComponentOpacity(value.props.value)
    let _theme = PreviewTheme(selectColor, buttonVariant, componentVariant, 
      bgSelectColor, componentBGColor,  value.props.value, questionFontSize, backgroundImage,
      componentFontSize,fontColor,fontFamily)
    setTheme(_theme)
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    _surveyTheme.componentOpacity = value.props.value
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }

  const handleQuestionFontSize = (event, value) =>{
    setQuestionFontSize(value.props.value)
    let _theme = PreviewTheme(selectColor, buttonVariant, componentVariant,
       bgSelectColor, componentBGColor,  componentOpacity, value.props.value, backgroundImage,
       componentFontSize,fontColor,fontFamily)
    setTheme(_theme)
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    _surveyTheme.questionFontSize = value.props.value
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }

  const handleBackGroundImageChange = (e, value) =>{
    e.preventDefault();
    setBackgroundImage(value)
    let _theme = PreviewTheme(selectColor, buttonVariant, componentVariant, 
      bgSelectColor, componentBGColor,  componentOpacity, questionFontSize, value,
      componentFontSize,fontColor,fontFamily)
    setTheme(_theme)
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    _surveyTheme.backgroundImage = value
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }

  const handleFontFamilyChange= (event, value)=>{
    setFontFamily(value.props.value)
    let _theme = PreviewTheme(selectColor, buttonVariant, componentVariant,
       bgSelectColor, componentBGColor,  componentOpacity, questionFontSize, backgroundImage, 
       componentFontSize,fontColor,value.props.value)
    setTheme(_theme)
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    _surveyTheme.fontFamily = value.props.value
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }

  const handleComponentFontSizeChange= (event, value)=>{
    setComponentFontSize(value.props.value)
    let _theme = PreviewTheme(selectColor, buttonVariant, componentVariant,
       bgSelectColor, componentBGColor,  componentOpacity, questionFontSize, backgroundImage,
       value.props.value,fontColor,fontFamily)
    setTheme(_theme)
    let survey = props.surveyData
    let _surveyTheme = surveyTheme()
    _surveyTheme.componentFontSize = value.props.value
    survey.theme = _surveyTheme
    survey.edited = true
    props.editSurvey(survey)
  }
  const popover = {
    position: 'absolute',
    zIndex: '2',
    top: '48px',
    opacity: '1',
    visibility: 'visible',
    overflowY:'scroll'
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }
    const handleColorClick = (color) => {
      setDisplayColorPicker(!displayColorPicker)
    };
  
    const handleColorClose = () => {
      setDisplayColorPicker(false)
    };
    const handleAddLogo = () =>{

    }
   
    const handleBGColorClick = (color) => {
      setDisplayBGColorPicker(!displayBGColorPicker)
    };
  
    const handleBGColorClose = () => {
      setDisplayBGColorPicker(false)
    };


    const handleComponentBGColorClick = (color) => {
      setDisplayComponentBGColorPicker(!displayComponentBGColorPicker)
    };
  
    const handleComponentBGColorClose = () => {
      setDisplayComponentBGColorPicker(false)
    };

    const handleFontColorClick = (color) => {
      setDisplayFontColorPicker(!displayFontColorPicker)
    };
  
    const handleFontColorClose = () => {
      setDisplayFontColorPicker(false)
    };
   
    const welcomeContinue = () =>{
      setShowWelcomePage(false)
    }

  const mystyle = backgroundImage!= "None" ? {
    backgroundImage: "url(" + backgroundImage + ")",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  } : {backgroundColor:bgSelectColor};

  //****************** ********************//
  const ageContainer =()=>{
    return (
      <Fragment>
        <div className="mb-3">
            <TextField
            variant="outlined"
            label="Age"
            fullWidth
            placeholder="Enter your password"
            type="number"
            // value={age}
            // onChange={(event) => {setAge(event.target.value)}}
            autoComplete="false"
            />
        </div>
      </Fragment>
    )
  }

  const nameContainer =()=>{
    return (
      <Fragment>
        <div className="mb-3">
            <TextField
            variant="outlined"
            label="Name"
            fullWidth
            placeholder="Enter your Name"
            type="text"
            // value={name}
            // onChange={(event) => {setName(event.target.value)}}
            autoComplete="false"
            />
        </div>
      </Fragment>
    )
  }

  const emailContainer =()=>{
    return (
      <Fragment>
        <div className="mb-3">
            <TextField
            variant="outlined"
            label="Email"
            fullWidth
            placeholder="Enter your Name"
            type="text"
            // value={email}
            // onChange={(event) => {setEmail(event.target.value)}}
            autoComplete="false"
            />
        </div>
      </Fragment>
    )
  }

  const phoneContainer =()=>{
    return (
      <Fragment>
        <div className="mb-3">
            <TextField
            variant="outlined"
            label="Phone Number"
            fullWidth
            placeholder="Enter your phone number"
            type="text"
            // value={phone}
            // onChange={(event) => {setPhone(event.target.value)}}
            autoComplete="false"
            />
        </div>
      </Fragment>
    )
  }
  
  const genderContainer =()=>{
    const genderObjList= [
      {
          id:0,
          name: "Female"
      },
      {
        id:1,
        name: "Male"
      },
      {
        id:2,
        name: "others"
      },
      {
        id:-1,
        name: "prefer not to answer"
      }
  ]
    return (
      <Fragment>
        <div className="mb3">
          <Autocomplete className="my-3"
            id="Gender"
            size="medium"
            options={genderObjList}
            getOptionLabel={option => option.name}
            defaultValue={genderObjList[3]}
            // onChange={(event, values) => values ? setGender(values.id): null}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Gender"
                placeholder="Gender"
                fullWidth
              />
            )}
            autoComplete={false}
          />
        </div>
      </Fragment>
    )
  }
  const cityContainer =()=>{
    if (!countryList.length){
      Authorization.getCountry().then(response => {
          setCountryList(response)
      })
    }
    
    const handleCountryChange = (event, values) =>{
      setCountry(values.name)
        Authorization.getCity(values.name).then(response => {
          setCityList(response)
        })
    }
    return (
      <Fragment>
        <div className="mb3">
                <Autocomplete className="my-3"
                    id="country"
                    size="medium"
                    options={countryList}
                    getOptionLabel={option => option.name}
                    onChange={handleCountryChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Country"
                        placeholder="Country"
                        fullWidth
                      />
                    )}
                    autoComplete={false}
                  />
        </div>
        <div className="mb3">
          <Autocomplete className="my-3"
                    id="city"
                    size="medium"
                    options={cityList}
                    getOptionLabel={option => option.name}
                    // onChange={(event, values) => values? setCity(values.name) : null}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="City"
                        placeholder="City"
                        fullWidth
                      />
                    )}
                    autoComplete={false}
                  />
        </div>
      </Fragment>
    )
  }

 const anonymousContaner = () =>{
   return(
     <Fragment>
       {(anonymousUserRequiredFields.name || anonymousUserRequiredFields.email || anonymousUserRequiredFields.phone ||
       anonymousUserRequiredFields.age|| anonymousUserRequiredFields.gender || anonymousUserRequiredFields.city) ?
       <Grid container spacing={4} alignItems="center" justify="center">
            <Grid item xs={8} lg={8} md={8}>
                <Card className="mt-4" style={{ backgroundColor: theme.componentBgColorRgba, opacity: theme.componentOpacity}}>
                  <CardContent className="p-3">
                  <div className="justify-content-between mb-4"  >
                    {anonymousUserRequiredFields.name ? nameContainer():  null }
                    {anonymousUserRequiredFields.email ? emailContainer():  null }
                    {anonymousUserRequiredFields.phone ? phoneContainer():  null }
                    {anonymousUserRequiredFields.age ? ageContainer():  null }
                    {anonymousUserRequiredFields.gender ? genderContainer():  null }
                    {anonymousUserRequiredFields.city ? cityContainer() :  null }
                  </div>
                  </CardContent>
                </Card>
            </Grid>
        </Grid> : null }
     </Fragment>
   )
 }

  const [tab, setTab] = useState(0);
  return (
    <Fragment>
    <div className="app-preview-body " style={mystyle}>
      <div className="app-preview-sidebar" style={{height:' 75vh' ,backgroundColor:"#fff"}}>
      <div className="side-overflow">
        <Box bgcolor="white" p={0} m={0}>
              <Box className="app-sidebar-footer-wrapper">
                <ul className="app-sidebar-footer">
                  <li>
                    <Tooltip arrow title="Questions">
                      <IconButton onClick={() => setTab(0)} color={tab == 0 ? "primary" : "default"}>
                        <List />
                      </IconButton>
                    </Tooltip>
                  </li>
                  <li>
                    <Tooltip arrow title="Design">
                      <IconButton onClick={() => setTab(1)} color={tab == 0 ? "primary" : "default"}>
                        <Brush />
                      </IconButton>
                    </Tooltip>
                  </li>
                </ul>
          </Box>
        </Box>
        <TabPanel value={tab} index={0}>
        {state.questions.map((e, i) => (
          <Fragment key={e.feQId}>
            <ListItem button className="py-3 border-0" onClick={viewSelecedQuestion.bind(null, i)}>
              <div className="align-box-row w-100">
                <div className="mr-3">
                  <div className="bg-neutral-dark text-primary text-center font-size-xl d-40 rounded-sm">
                    {i+1}
                  </div>
                </div>
                <div className="font-weight-bold d-block opacity-8 text-truncate">
                  {e.questionText}
                </div>
              </div>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
        </TabPanel>
        <TabPanel value={tab} index={1}>
        <ListItem className="py-2" >
              <div className="align-box-row w-100">
              <Button variant="outlined" color="secondary"  onClick={handlePageDialogOpen('paper')} className="w-100">Add Page</Button>
              </div>
          </ListItem>
        <ListItem className="py-2" >
              <div className="align-box-row w-100">
              <Button variant="outlined" color="secondary"  onClick={handleLogoDialogOpen('paper')} className="w-100">Add Logo</Button>
              </div>
          </ListItem>
          <ListItem className="py-2" >
              <div className="align-box-row w-100">
              <Button className="w-100"  variant="outlined" color="secondary" onClick={handleBackGroundImage('paper')}>Background Image</Button>
              </div>
          </ListItem>
          <ListItem className="py-2" >
              <div className="align-box-row w-100">
              <Button variant="outlined" color="secondary"  onClick={handleBGColorClick} className="w-100">Background Color</Button>
                {displayBGColorPicker ? <div className="dropdown-menu p-0 m-0" style={popover}>
                    <div style={cover} onClick={handleBGColorClose}/>
                    <SketchPicker  color={bgSelectColor} onChangeComplete={handleBGColorChange}  />
                </div> : null}
              </div>
          </ListItem>
          <ListItem className="py-2" >
              <div className="align-box-row w-100">
              <Button variant="outlined" color="secondary"  onClick={handleColorClick} className="w-100">Component Color</Button>
                {displayColorPicker ? <div className="dropdown-menu p-0 m-0" style={popover}>
                    <div style={cover} onClick={handleColorClose}/>
                    <SketchPicker  color={selectColor} onChangeComplete={handleColorChange}  />
                </div> : null}
              </div>
          </ListItem>
          <ListItem className="py-2" >
              <div className="align-box-row w-100">
              <Button variant="outlined" color="secondary"  onClick={handleComponentBGColorClick} className="w-100">Component BG Color</Button>
                {displayComponentBGColorPicker ? <div className="dropdown-menu p-0 m-0" style={popover}>
                    <div style={cover} onClick={handleComponentBGColorClose}/>
                    <SketchPicker  color={componentBGColor} onChangeComplete={handleComponentBGColorChange} style={{zIndex:1}} />
                </div> : null}
              </div>
          </ListItem>
          <ListItem className="py-2" >
              <div className="align-box-row w-100">
              <Button variant="outlined" color="secondary"  onClick={handleFontColorClick} className="w-100">Font Color</Button>
                {displayFontColorPicker ? <div className="dropdown-menu p-0 m-0" style={popover}>
                    <div style={cover} onClick={handleFontColorClose}/>
                    <SketchPicker  color={fontColor} onChangeComplete={handleFontColorChange} style={{zIndex:1}} />
                </div> : null}
              </div>
          </ListItem>
          <ListItem className="py-2" >
              <div className="align-box-row w-100">
                <TextField
                    select
                    size="small"
                    label="Component Style"
                    value={componentVariant}
                    onChange={handleComponentVariantChange}
                    // helperText="Component style"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                    <MenuItem value="standard" key="standard">standard</MenuItem>
                    <MenuItem value="outlined" key="outlined">outlined</MenuItem>
                  </TextField>
              </div>
          </ListItem>
          <ListItem className="py-2" >
              <div className="align-box-row w-100">
                <TextField
                    select
                    size="small"
                    label="Opacity"
                    value={componentOpacity}
                    onChange={handleComponentOpacity}
                    // helperText="Component style"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                    <MenuItem value="1" key="1">1</MenuItem>
                    <MenuItem value="0.9" key="0.9">0.9</MenuItem>
                    <MenuItem value="0.8" key="0.8">0.8</MenuItem>
                    <MenuItem value="0.7" key="0.7">0.7</MenuItem>
                    <MenuItem value="0.6" key="0.6">0.6</MenuItem>
                  </TextField>
              </div>
          </ListItem>
          <ListItem className="py-2" >
              <div className="align-box-row w-100">
                <TextField
                    select
                    size="small"
                    label="Question Font Size"
                    value={questionFontSize}
                    onChange={handleQuestionFontSize}
                    // helperText="Component style"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                    <MenuItem value="18px" key="18">xs</MenuItem>
                    <MenuItem value="20px" key="20">medium</MenuItem>
                    <MenuItem value="22px" key="22">large</MenuItem>
                    <MenuItem value="24px" key="24">xl</MenuItem>
                  </TextField>
              </div>
          </ListItem>
          <ListItem className="py-2" >
              <div className="align-box-row w-100">
                <TextField
                    select
                    size="small"
                    label="component Font Size"
                    value={componentFontSize}
                    onChange={handleComponentFontSizeChange}
                    // helperText="Component style"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                    <MenuItem value="14px" key="x-14">xs</MenuItem>
                    <MenuItem value="16px" key="x-16">medium</MenuItem>
                    <MenuItem value="18px" key="x-18">large</MenuItem>
                    <MenuItem value="20px" key="x-20">xl</MenuItem>
                  </TextField>
              </div>
          </ListItem>
          <ListItem className="py-2 " >
              <div className="w-100">
                <TextField
                        select
                        size="small"
                        label="Button style"
                        value={buttonVariant}
                        onChange={handleButtonVariantChange}
                        // helperText="buttons style"
                        fullWidth
                        variant="outlined"
                        color="primary"
                      >
                        <MenuItem value="contained" key="contained">Fill Inside</MenuItem>
                        <MenuItem value="outlined" key="outlined">Outlined</MenuItem>
                        <MenuItem value="text" key="text">Just Text</MenuItem>
                  </TextField>
              </div>
          </ListItem>
          <ListItem className="py-2 " >
              <div className="w-100">
                <TextField
                        select
                        size="small"
                        label="Font style"
                        value={fontFamily}
                        onChange={handleFontFamilyChange}
                        // helperText="buttons style"
                        fullWidth
                        variant="outlined"
                        color="primary"
                      >
                        <MenuItem value="1" key="z-1">Default</MenuItem>
                        <MenuItem value="2" key="z-2">Arial</MenuItem>
                        <MenuItem value="3" key="z-3">Verdana</MenuItem>
                        <MenuItem value="4" key="z-4">Helvetica</MenuItem>
                        <MenuItem value="5" key="z-5">Tahoma</MenuItem>
                        <MenuItem value="6" key="z-6">Trebuchet</MenuItem>
                        <MenuItem value="7" key="z-7">Times New Roman</MenuItem>
                        <MenuItem value="8" key="z-8">Georgia</MenuItem>
                        <MenuItem value="9" key="z-9">Garamond</MenuItem>
                        <MenuItem value="10" key="z-10">Courier New</MenuItem>
                        <MenuItem value="11" key="z-11">Brush Script MT</MenuItem>
                  </TextField>
              </div>
          </ListItem>
          
        </TabPanel>
        </div>
      </div>
      <div className="app-preview-content " style={{height:' 75vh'}}>
        <div className="side-overflow">
          <div className="p-2 app-submission-header">
          <Grid container spacing={1} justify="center">
              <Grid item xs={2} lg={2} md={2}>
              {skip ? (
                <Button color={theme.color} variant={theme.buttonVariant} onClick={changeSkipVerification}>Disable skip logic</Button>
              ) : (
                <Button color={theme.color} variant={theme.buttonVariant} onClick={changeSkipVerification}>Enable skip logic</Button>
              )}
              </Grid>
 
            <Grid item xs={2} lg={2} md={2}> 
              <Button  onClick={startOver} color={theme.color} variant={theme.buttonVariant}>
                <Refresh /> restart
              </Button>
              </Grid>
          </Grid>
          </div> 
          {(welcomePageTitle != "" || welcomePageText !=  "" || welcomePageSubText !== "") && showWelcomePage == true ? 
          <PageContainer theme={theme} welcomePageTitle={welcomePageTitle} 
          welcomePageText={welcomePageText} welcomePageSubText={welcomePageSubText} type={"welcome" } welcomeContinue={welcomeContinue}/> :   
          <ThemeProvider theme={theme.pallet}>
              <div className="d-block p-4 w-100">
                {state.state == 'start' && (
                  <Fragment>
                    <div className="p-4 m-4">{anonymousContaner()}</div>
                  <div className="p-4 m-4 text-center">
                    <Button color={theme.color} variant={theme.buttonVariant} size="large" onClick={startSurvey}>
                      Start Survey
                    </Button>
                  </div>
                  </Fragment>
                )}
                {state.state == 'end' && (
                  <PageContainer theme={theme} data={thankYouPage} type={"thankyou"}/>
                )}
                {state.state == 'questions' && state.questions.length > 0 ? (
                  <QuestionRender key={state.questions[state.selected].feQId} data={state.questions[state.selected]} index={state.selected} 
                  preview={false} submit={submitAnswer} save={saveAnswer} theme={theme} questionPreview={true}/>
                ) : null}
              </div>
          </ThemeProvider>
        }
          {(logo != "" && logo !="None")? 
          <div className="p-4 m-4">
            <Grid container spacing={2} alignItems="center" justify="center" style={{position: "absolute", bottom: "1px"}}>
              <Grid item xs={4} lg={4} md={4} >
              <div className="card-img-wrapper" >
                <img src={logo}  className="card-img-top rounded" alt="..." style={{maxWidth: logoWidth+"px", maxHeight:logoHeight+"px" , 
                  display:"block", marginLeft:0, marginRight:0}}/>
              </div>
              </Grid>
            </Grid>
          </div>
          :null}  
        </div>
        
      </div>
    </div>
    <div>
    < BgImageDialog openBgDialog={openBgDialog} handleBGDialigClose={handleBGDialigClose} scroll={scroll}
     handleBackGroundImageChange={handleBackGroundImageChange} handleBGDialigClose={handleBGDialigClose} />
     <PageDialog  openPageDialog={openPageDialog} handlePageDialigClose={handlePageDialigClose} scroll={scroll}
        setWelcomePageTitle={setWelcomePageTitle} setWelcomePageText={setWelcomePageText} setWelcomePageSubText={setWelcomePageSubText}
        setThankYouPage={setThankYouPage} handleSavePageData={handleSavePageData} handlePageDialigClose={handlePageDialigClose} 
        welcomePageTitle={welcomePageTitle} welcomePageText={welcomePageText} 
        welcomePageSubText={welcomePageSubText} thankYouPage={thankYouPage}/>

      <LogoDialog openLogoDialog={openLogoDialog} handleLogoDialigClose={handleLogoDialigClose} scroll={scroll}
      handleLogoDialigClose={handleLogoDialigClose} handleLogoSave={handleLogoSave} logo={logo} setLogo={setLogo}
      logoWidth={logoWidth} setLogoWidth={setLogoWidth} logoHeight={logoHeight} setLogoHeight={setLogoHeight} id={props.surveyData.surveyTypeId}/>
      

      
      </div>
    </Fragment>
  )
}

export default ViewSurvey;
