import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
 
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.primary,
    },
    grid:{
        margin:2
    }
}));

export default (props) => {

    let data = [...props.values];
    data.sort((a, b) => {
        return b.count - a.count;
    });
    let categories = [];
    let values = [];
    data.forEach((e) => {
        categories.push(e.value);
        values.push(e.count);
    })
    const classes = useStyles();


    return (
        <div  className={classes.root} style={{maxHeight:'400px', overflowY:'scroll'}}>
            <Grid container >
                {categories.map(
                    (e) =>
                        <Grid item className={classes.grid} >
                            <Paper  className={classes.paper}>
                                {e}
                            </Paper>
                        </Grid>
                )
                }
            </Grid>
        </div>
    );
}