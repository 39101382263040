import React, { Fragment, useState } from 'react';
import { FormGroup, FormControlLabel, Radio, RadioGroup, Checkbox, Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { CircularProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
export default (props) => {
  const [answer, setAnswer] = useState([]);
  const [isAnswered,setIsAnswered] = useState(false)
  const [progressbar, setProgressbar] = useState(false);
  let Sel = (props) => {
    if (props.type == 'single') {
      return <Radio color={props.color} />
    }
    else {
      return <Checkbox color={props.color} checked={props.checked} onChange={props.onChange} name={props.name} />
    }
  };
  let Group = (props) => {
    if (props.type == 'single') {
      return (
        <RadioGroup value={props.value} onChange={props.onChange} row>
          {props.children}
        </RadioGroup>
      )
    }
    else {
      return (
        <FormGroup row>
          {props.children}
        </FormGroup>
      )
    }
  };
  let values = props.questionObject.options || [];

  const handleChange = (event) => {
    let newArr = [ ...answer ];
    if (props.type == 'single') {
      newArr = [event.target.name];
    }
    else {
      let name = event.target.name;
      let index = newArr.indexOf(name);
      if (index >= 0) {
        newArr.splice(index, 1);
      }
      else {
        newArr.push(name);
      }
    }
    setAnswer(newArr);
    if (!props.preview) {
      props.save({
        value: newArr
      });
    }
  };

  const selectImage = (url) => {
    let newArr = [ ...answer ];
    if (props.type == 'single') {
      newArr = [url];
    }
    else {
      let index = newArr.indexOf(url);
      if (index >= 0) {
        newArr.splice(index, 1);
      }
      else {
        newArr.push(url);
      }
    }
    setAnswer(newArr);
    if (!props.preview) {
      props.save({
        value: newArr
      });
    }
  }

  const submitAnswer = () => {
    if (answer.length === 0 )
    {
        setIsAnswered(true)
    }
    else{
        setIsAnswered(false)
        setProgressbar(true)
        props.submit({
          value: answer
        });
    }

  }

  return (
    <Fragment>
      <ThemeProvider theme={props.theme.pallet}>
      {props.content == "text" ? (
        <Group value={answer.length > 0 ? answer[0] : ""} onChange={handleChange}>
          {values.map((e, i) => {
            return (
              <FormControlLabel key={i}
                control={
                  <Sel checked={answer.indexOf(e) >= 0} onChange={handleChange} name={e} color={props.theme.color}/> 
                }
                label={<span style={{fontSize:props.theme.componentFontSize, color:props.theme.fontColor, fontFamily:props.theme.fontFamily
                   }}>{e}</span>}
                value={e}
              />
            )
          })}
        </Group>
      ) : (
        <div className="align-content-center justify-content-around" style={{whiteSpace: "initial"}}>
          {values.map((e, i) => {
            return (
              <Button key={e.finUrl} className="m-3" color={props.theme.color} variant={answer.indexOf(e.finUrl) >= 0 ? "contained" : "outlined"} onClick={selectImage.bind(null, e.finUrl)}>
                <div className="d-flex flex-column align-item-center">
                  <img className="p-2" src={e.finUrl} style={{width: '10rem', height: '10rem', borderRadius: '0.25rem'}} />
                </div>
              </Button>
            )
          })}
        </div>
      )}
      {isAnswered ? <div>
                    <Alert severity="error">Please Answer </Alert>
                </div> : null}
      <div className="d-block">
                {props.preview ? null : (<Button className="mt-3" color={props.theme.color} variant={props.theme.buttonVariant} onClick={submitAnswer}>Submit</Button>)}
                { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
      </div>
      </ThemeProvider>
    </Fragment>
  )
}