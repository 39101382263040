import React from 'react';
import {
  TextFields,
  ExposureZero,
  ShortText,
  Today,
  Schedule,
  EventNote,
  Publish,
  RadioButtonChecked,
  PhotoLibrary,
  CheckBox,
  Image,
  ArrowDropDown,
  Audiotrack,
  Videocam,
  StarBorder,
  SentimentVerySatisfied,
  OndemandVideo,
  MoreHoriz
} from '@material-ui/icons';

import { InputCreate, InputRender, InputValidate } from './InputType';
import { FileUploadCreate, FileUploadRender, FileUploadValidation } from './FileUpload';
import { SelectionCreate, SelectionRender, SelectionValidate } from './Selections';
import { EmoticonsCreate, EmoticonsRender, EmoticonsValidate } from './EmotionIcons'
import { DropdownCreate, DropdownRender, DropdownValidate } from './Dropdown';
import { RatingCreate, RatingRender, RatingValidate } from './Rating';
import { VideoCreate, VideoRender, VideoValidate } from './Video';
import { AudioCreate, AudioRender, AudioValidate } from './Audio';
import { LikertCreate, LikertRender, LikertValidate } from './Likert'
import { MatrixCreate, MatrixRender, MatrixtValidate } from './Matrix'
import { VideoEmotionCreate, VideoEmotionRender, VideoEmotionValidate } from './VideoEmotion';
import IosVideocamOutline  from 'react-ionicons/lib/IosVideocam'
import IosApps from 'react-ionicons/lib/IosApps'
import IosMicOutline from 'react-ionicons/lib/IosMic'
import IosImagesOutline from 'react-ionicons/lib/IosImages'
import IosImageOutline from 'react-ionicons/lib/IosImage'

const questionList = {
  video: {
    name: "Video",
    id: "video",
    answerCode: 1,
    icon: <IosVideocamOutline color="var(--primary)"/>,
    create: (props) => <VideoCreate  {...props} />,
    render: (props) => <VideoRender {...props} />,
    condition: VideoValidate
  },
  audio: {
    name: "Audio",
    id: "audio",
    answerCode: 1,
    icon: <IosMicOutline color="var(--primary)"/>,
    create: (props) => <AudioCreate  {...props} />,
    render: (props) => <AudioRender {...props} />,
    condition: AudioValidate
  },
  text: {
    name: "Input",
    id: "text",
    answerCode: 8,
    icon: <TextFields color="primary" />,
    create: (props) => <InputCreate range={false} type="text" {...props} />,
    render: (props) => <InputRender type="text" {...props} />,
    condition: InputValidate
  },
  number: {
    name: "Number",
    id: "number",
    answerCode: 17,
    icon: <ExposureZero color="primary" />,
    create: (props) => <InputCreate range={true} type="number" {...props} />,
    render: (props) => <InputRender type="number" {...props} />,
    condition: InputValidate
  },
  textarea: {
    name: "Text Box",
    id: "textarea",
    answerCode: 18,
    icon: <ShortText color="primary" />,
    create: (props) => <InputCreate range={false} type="text" {...props} />,
    render: (props) => <InputRender type="text" {...props} />,
    condition: InputValidate
  },
  
  singleTextSelect: {
    name: "Single Text Selection",
    id: "singleTextSelect",
    answerCode: 21,
    icon: <RadioButtonChecked color="primary" />,
    create: (props) => <SelectionCreate type="single" content="text" {...props} />,
    render: (props) => <SelectionRender type="single" content="text" {...props} />,
    condition: SelectionValidate.text
  },
  singleImageSelect: {
    name: "Single Image Selection",
    id: "singleImageSelect",
    answerCode: 22,
    icon: <IosImageOutline color="var(--primary)"/>,
    create: (props) => <SelectionCreate type="single" content="image" {...props} />,
    render: (props) => <SelectionRender type="single" content="image" {...props} />,
    condition: SelectionValidate.image
  },
  multiTextSelect: {
    name: "Multiple Text Selection",
    id: "multiTextSelect",
    answerCode: 10,
    icon: <CheckBox color="primary" />,
    create: (props) => <SelectionCreate type="multi" content="text" {...props} />,
    render: (props) => <SelectionRender type="multi" content="text" {...props} />,
    condition: SelectionValidate.text
  },
  multipleImageSelect: {
    name: "Multiple Image Selection",
    id: "multipleImageSelect",
    answerCode: 23,
    icon: <IosImagesOutline color="var(--primary)"/>,
    create: (props) => <SelectionCreate type="multi" content="image" {...props} />,
    render: (props) => <SelectionRender type="multi" content="image" {...props} />,
    condition: SelectionValidate.image
  },
  matrix: {
    name: "Matrix",
    id: "matrix",
    answerCode: 28,
    icon: <IosApps color="var(--primary)" />,
    create: (props) => <MatrixCreate  {...props} />,
    render: (props) => <MatrixRender {...props} />,
    condition: MatrixtValidate
  },
  likert: {
    name: "likert",
    id: "likert",
    answerCode: 25,
    icon: <MoreHoriz color="primary" />,
    create: (props) => <LikertCreate  {...props} />,
    render: (props) => <LikertRender {...props} />,
    condition: LikertValidate
  },
  emotionIcons: {
    name: "Emotion Icons",
    id: "emotionIcons",
    answerCode: 16,
    icon: <SentimentVerySatisfied color="primary" />,
    create: (props) => <EmoticonsCreate {...props}/>,
    render: (props) => <EmoticonsRender {...props} />,
    condition: EmoticonsValidate
  },
  dropDown: {
    name: "Dropdown",
    id: "dropDown",
    answerCode: 9,
    icon: <ArrowDropDown color="primary" />,
    create: (props) => <DropdownCreate type="single" content="text" {...props} />,
    render: (props) => <DropdownRender type="single" content="text" {...props} />,
    condition: DropdownValidate
  },
  ratingSelect: {
    name: "Rating",
    id: "ratingSelect",
    answerCode: 24,
    icon: <StarBorder color="primary" />,
    create: (props) => <RatingCreate type="single" content="text" {...props} />,
    render: (props) => <RatingRender type="single" content="text" {...props} />,
    condition: RatingValidate
  },

  videoEmotion: {
    name: "Video Emotion",
    id: "videoEmotion",
    answerCode: 20,
    icon: <OndemandVideo color="primary" />,
    create: (props) => <VideoEmotionCreate  {...props} />,
    render: (props) => <VideoEmotionRender {...props} />,
    condition: VideoEmotionValidate
  },
  
  date: {
    name: "Date Only",
    id: "date",
    answerCode: 5,
    icon: <Today color="primary" />,
    create: (props) => <InputCreate range={true} type="date" {...props} />,
    render: (props) => <InputRender type="date" {...props} />,
    condition: InputValidate
  },
  time: {
    name: "Time Only",
    id: "time",
    answerCode: 19,
    icon: <Schedule color="primary" />,
    create: (props) => <InputCreate range={true} type="time" {...props} />,
    render: (props) => <InputRender type="time" {...props} />,
    condition: InputValidate
  },
  datetime: {
    name: "Date & Time",
    id: "datetime",
    answerCode: 6,
    icon: <EventNote color="primary" />,
    create: (props) => <InputCreate range={true} type="datetime-local" {...props} />,
    render: (props) => <InputRender type="datetime-local" {...props} />,
    condition: InputValidate
  },
  file: {
    name: "File Upload",
    id: "file",
    answerCode: 20,
    icon: <Publish color="primary" />,
    create: (props) => <FileUploadCreate {...props} />,
    render: (props) => <FileUploadRender {...props} />,
    condition: FileUploadValidation
  }
}

export default questionList;