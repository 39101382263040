import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DropDown from './dropDown'
import {
    Grid,
    IconButton,
    Card,
    Divider,
  } from '@material-ui/core';


export default function Tables(props) {

    const ArrayToSpan= (obj) => {
        if(obj && obj.length){
            return (
                <Fragment>
                { 
                    obj.map((data,idx) => (
                        <span key={idx}>{ (idx ? ', ' : '') + data }</span>
                    ))
                    }
                </Fragment>);
        }
        return null
    }
    const status = (id) =>{
        if (id === 1){
            return (<span className="m-1 badge bg-neutral-success">Done</span>)
        }
        else{
            return (<span className="m-1 badge bg-neutral-info">Running</span>)
        }
    }

    const Gender = (obj) =>{
        if (obj=== 1){
            return (<IconButton size="small" color="primary">
                        <FontAwesomeIcon icon={['fas', 'male']}  />
                    </IconButton>)
        }
        else if (obj=== 0){
            return (<IconButton size="small" color="primary">
                        <FontAwesomeIcon icon={['fas', 'female']}  />
                    </IconButton>)
        }
        else{
            return (<IconButton size="small" color="primary">
            <FontAwesomeIcon icon={['fas', 'male']}  />
            <FontAwesomeIcon icon={['fas', 'female']}  />
            </IconButton>)
        }
    }

    const TableContent = () => {
        return (
            <Fragment>
            { 
                props.data.map((data,idx) => (
                    
                <Fragment key={idx}>
                    <tr>
                    <td className="text-center">
                        {status(data.status)}
                    </td>
                    <td>
                        <div className="d-flex align-items-center">
                            <div>
                            <b>{data.title}</b>
                            <span className="text-black-50 d-block" style={{whiteSpace:'initial'}}> 
                                {data.notes}
                            </span>
                            <span className="text-black-50 d-block">
                                created at: {data.created_at}
                            </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="align-box-row">{data.surveyTypeName}</div>
                    </td>
                    <td >
                    <div className="d-flex align-items-center">
                            <div>
                            <span className="text-black-50 d-block">
                            Started : {data.startCount}
                            </span>
                            <span className="text-black-50 d-block">
                            Completed : {data.completedCount}
                            </span>
                            {/* <span className="text-black-50 d-block">
                            Average Time Taken: {data.averageTimeTaken}
                            </span> */}
                            </div>
                        </div>
    
                    </td>
                    <td className="text-center">
                        <div className="d-flex align-items-center">
                            {/* <span className="text-black-50 d-block"> */}
                                {data.updated_at}
                            {/* </span> */}
                        </div>
                    </td>
                    {/* <td>
                        <div className="d-flex align-items-center">
                            <div>
                            <span className="text-black-50 d-block">
                                gender: {Gender(data.genderGroup)}
                            </span>
                            <span className="text-black-50 d-block">
                                age: { ArrayToSpan(data.ageGroup)}
                            </span>
                            <span className="text-black-50 d-block">
                                Language: { data.language}
                            </span>
                            <span className="text-black-50 d-block" style={{whiteSpace:'initial'}}>
                                Country List: { ArrayToSpan(data.countryList) }
                            </span>
                            </div>
                        </div>
                    </td> */}
                    {/* <td className="text-center">
                        { ArrayToSpan(data.countryList) }
                    </td> */}
                    <td className="text-center">
                    <DropDown id={data.survey_id} name={data.title}/>
                    </td>
                </tr>
            </Fragment>
                    
            ))
            }
            </Fragment>);
    }

  return (
    <Fragment>
        <Grid container spacing={10} justify='center'>
                <Grid item xs={12} md={11} lg={10} >
    <Card className="card-box mb-4">
        <div className="table-responsive">
        <table className="table  table-hover table-bordered text-nowrap mb-0">
            <thead className="thead-light">
            <tr>
                <th className="text-center" style={{ width: '5%' }}>status</th>
                <th  className="text-center" style={{ width: '10%'}}>Title</th>
                <th className="text-center" style={{ width: '6%' }}>Survey Type</th>
                <th className="text-center" style={{ width: '5%' }}>Responses</th>
                <th className="text-center" style={{ width: '5%' }}>Modified</th>
                {/* <th className="text-center" style={{ width: '10%' }}>Demographic</th> */}
                <th className="text-center" className="text-center" style={{ width: '5%' }}>Actions</th>
            </tr>
            </thead>
            <tbody>
                <TableContent/>
            </tbody>
        </table>
        </div>
        <Divider />
    </Card>
    </Grid>
    </Grid>
    </Fragment>
  );
}
