import React, { Fragment } from 'react'  
import ReactHTMLTableToExcel from 'react-html-table-to-excel';  

export default (props) => {

    const getName = (name)=>{
        var filename = name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        if (filename === ""){
            return Math.random().toString(36).slice(2)
        }
        return filename
    }

    return (
        <Fragment>
        <div style={{float: 'right'}}>
            <ReactHTMLTableToExcel className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary mb-2"  
            table={props.id.toString()}  
            filename={getName(props.name)}
            sheet="Sheet"  
            buttonText="Export excel" /> 
        </div>
        <div className="table-responsive" style={{maxHeight:'400px', overflowY:'scroll'}}>
            <table className="table table-striped table-hover table-bordered mb-4" id={props.id.toString()}>
            <thead className="thead-light" >
                <tr>
                {/* <th scope="col">id</th>
                <th scope="col">user Type</th> */}
                <th scope="col">Value</th>
                <th scope="col">Age</th>
                <th scope="col">Gender</th>
                <th scope="col">City</th>
                <th scope="col">State</th>
                <th scope="col">Country</th>
                </tr>
                <tr>
            </tr>
            </thead>
            <tbody>
            { props.values.map((e, idx) => { 
                return(
                <tr key={idx}>
                {/* <th scope="row">{e.id}</th> */}
                <td>{ JSON.stringify(e.data)}</td>
                <td>{e.age}</td>
                <td>{e.gender}</td>
                <td>{e.city}</td>
                <td>{e.state}</td>
                <td>{e.country}</td>
              </tr>
                )
                
             })
            }
            </tbody>
            </table>
        </div>
        </Fragment>
    )
}
