import React, { useState, Fragment } from 'react';
import { Button, IconButton, ListItem,Dialog, DialogTitle, DialogContent,
    DialogActions, CardContent,
     Divider ,TextField, Grid,   Box,Card,
     Tooltip,} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

export default function PageDialog (props)  {
  return (
    <Fragment>
      <Dialog
        open={props.openPageDialog}
        onClose={props.handlePageDialigClose}
        scroll={props.scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Add Page</DialogTitle>
        <DialogContent dividers={props.scroll === 'paper'}>
          <div className="d-block mb-4 pb-3">  
          <Grid container spacing={1} alignItems="center" justify="center">
            <Grid item xs={8} lg={8} md={8}>
              <h2> Welcome Page</h2>
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
              className="m-1"
              label="Title"
              placeholder="Title"
              multiline
              fullWidth
              variant="outlined"
              value={props.welcomePageTitle}
              onChange={(event) => {props.setWelcomePageTitle(event.target.value)}}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
              className="m-1"
              label="Text"
              placeholder="Text"
              multiline
              fullWidth
              variant="outlined"
              value={props.welcomePageText}
              onChange={(event) => {props.setWelcomePageText(event.target.value)}}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
              className="m-1"
              label="Sub Text"
              placeholder="Sub Text"
              multiline
              fullWidth
              variant="outlined"
              value={props.welcomePageSubText}
              onChange={(event) => {props.setWelcomePageSubText(event.target.value)}}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="center" justify="center">
            <Grid item xs={8} lg={8} md={8}>
              <h3> Thank You Page</h3>
            </Grid>
              <Grid item xs={12} lg={12} md={12}>
              <TextField
              className="m-1"
              label="Title"
              placeholder="Title"
              multiline
              fullWidth
              variant="outlined"
              value={props.thankYouPage}
              onChange={(event) => {props.setThankYouPage(event.target.value)}}
              />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
        <Button onClick={props.handleSavePageData} variant="outlined" color="secondary">
            save
          </Button>
          <Button onClick={props.handlePageDialigClose} variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

    </Fragment>
  )
}
