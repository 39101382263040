import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Grid, Card } from '@material-ui/core';

export default (props) => {

  const loadData=()=>  {

        let data = props["values"];   
        const chart =
        {
            options: {
                chart: { id: "video emotion" },
                xaxis: { categories: [] },
            },

            series: [
                {
                    name: "Strong Dislike",
                    data: [],

                },
                {
                    name: "Dislike",
                    data: [],

                },
                {
                    name: "Neutral",
                    data: [],

                },
                {
                    name: "Like",
                    data: [],

                },
                {
                    name: "Strong Like",
                    data: [],

                }
            ]
        }
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
              var val = data[key];
              chart.series[0].data.push(val["Strong Dislike"])
              chart.series[1].data.push(val["Dislike"])
              chart.series[2].data.push(val["Neutral"])
              chart.series[3].data.push(val["Like"])
              chart.series[4].data.push(val["Strong Like"])
            }
        }
        return chart
    
    }
    
    const [chartData, setChartData] = useState(loadData())
    return (


        <Grid container >
            <Grid item xs={3}></Grid>
            <Grid item xs={6} >
                <Card >
                    <video width="100%" controls align="center">
                        <source src={props["media"]} type="video/mp4" />
                                Your browser does not support the video tag.
                    </video>
                </Card>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
                <Card >
                    <div className="app">
                        <div className="row">
                            <div className="mixed-chart" >
                                <Chart
                                    options={chartData.options}
                                    series={chartData.series}
                                    type="line"
                                    width="100%"
                                />
                            </div>
                        </div>
                    </div>
                </Card >
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>




    );

}


