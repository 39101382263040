
import React, { useEffect, useState } from 'react'
import {Fragment} from 'react';
import {
    TextField,
    IconButton,
    Button,
    MenuItem,
    Grid,
} from '@material-ui/core';
import { CircularProgress} from '@material-ui/core';
import { Clear, Add } from '@material-ui/icons';
import './index.css'
import { ThemeProvider } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const LikertRender = (props) =>{

    const [progressbar, setProgressbar] = useState(false);
    const [isAnswered,setIsAnswered] = useState(false)
    const [output, setOutput] = useState({});
    let five = ["Strongly agree", "Agree" , "Neutral", "Disagree", "Strongly disagree"]
    let values = props.questionObject.options || [{"type":'five',question:[],}];
    let type = (values != undefined) ? (values.hasOwnProperty('type') ? values.type :'five'):'five'
    let items = (values != undefined) ? (values.hasOwnProperty('type') ? values.typeValue : five):five;
    let classVal = `likert-form-${type}`
    let data =  (values != undefined) ? (values.hasOwnProperty('question')? values.question :[]):[]
    

    const headerObj = () =>{
        return (
            data.map((dataObj , i)=> (
                <div className={classVal} key={"x"+i} >
                <div className="font-size-lg p-3" > 
                  <span style={{fontSize:props.theme.questionFontSize, color:props.theme.fontColor, fontFamily:props.theme.fontFamily}}>{ dataObj}</span>
                  </div>
                    <ul className='likert' key={i} >
                        {buttonObj(dataObj)}
                    </ul>
                </div>
            ))
        )
    }

    const handelRadioChange = (event) =>{
        let obj = { ...output };
        obj[event.name] = event.value
        setOutput(obj)

    }
    const buttonObj = (text) =>{
        return (
            items.map((item , i) => (
                <li key={"y"+i}>
                    <input type="radio" name={text} value={item} style={{transform: 'scale(1.5)'}}
                    onChange={(event) => {handelRadioChange(event.target)}}
                    color={props.theme.color}/>
                    <span className="text-black-100 pr-2" style={{fontSize:props.theme.componentFontSize, color:props.theme.fontColor, fontFamily:props.theme.fontFamily}}>{item}</span>
                </li>
                )
            )

        )
    }

    const submitAnswer = () => {
        if (output.length === 0 )
        {
            setIsAnswered(true)
        }
        else{
            setIsAnswered(false)
            setProgressbar(true)
            props.submit({
                value: { ...output }
            })
        }
    }

    return (
        
        <Fragment>
            <ThemeProvider theme={props.theme.pallet}>
                {headerObj()}

                {isAnswered ? <div>
                    <Alert severity="error">Please Answer </Alert>
                </div> : null}
                <div className="d-block">
                    {props.preview ? null : (<Button className="mt-3" variant={props.theme.buttonVariant} color={props.theme.color} onClick={submitAnswer}>Submit</Button>)}
                    { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
                </div>
            </ThemeProvider>
        </Fragment>
        );
    }

function LikertCreate(props) {
    let five = ["Strongly agree", "Agree" , "Neutral", "Disagree", "Strongly disagree"]
    let four = ["Strongly agree", "Agree", "Disagree", "Strongly disagree"]
    let seven = ["Strongly agree", "Agree", "More or less agree", "Neither agree nor disagree", 
            "More or less disagree", "Disagree","Strongly disagree"]
    const dropObj= [
        {
            "id":"seven",
            "name": "7-point Likert scale"
        },
        {
            "id":"five",
            "name": "5-point Likert scale"
        },
        {
            "id":"four",
            "name": "4-point Likert scale"
        }
    ]
    const values = props.questionObject.options || [];
    const [type, setType] = useState((values != undefined) ? (values.hasOwnProperty('type') ? values.type :'five'):'five');
    const [question, setQuestion] =  useState((values != undefined) ? (values.hasOwnProperty('question')? values.question :[]):[]);
    const [typeValue, setTypeValue] = useState((values != undefined) ? (values.hasOwnProperty('question')? values.typeValue: five): five);

    const submitObj = () => {
        props.updateQuestionObject({
          options: {
            type: type,
            question:question,
            typeValue:typeValue
          }
        })
      }
    
    const handelTypeChange = value =>{
        setType(value)
        let _typeValue = getArr(value)
        setTypeValue(_typeValue)
        submitObj()
    }

    const getArr = value =>{
        if (value == "seven"){
            return seven
        }
        else if (value == "four"){
            return four
        }
        return five
    }

    const updateTypeItem = (text, index) => {
        let newValue = [...typeValue];
        newValue[index] = text;
        setTypeValue(newValue);
        let _value = values
        _value.typeValue = newValue
        props.updateQuestionObject({
            options:_value
        })
      }

    const addNewItem = () =>{
        let newValue = [...question];
        newValue.push("");
        setQuestion(newValue);
        let _value = values
        _value.question = newValue
        props.updateQuestionObject({
            options:_value
        })
    }
    const removeQuestions = (index)=>{
        let newValue = [...question];
        newValue.splice(index, 1);
        setQuestion(newValue);
        let _value = values
        _value.question = newValue
        props.updateQuestionObject({
            options:_value
        })
    }
    const updateQuestions = (text, index) => {
        let newValue = [...question];
        newValue[index] = text;
        setQuestion(newValue);
        let _value = values
        _value.question = newValue
        props.updateQuestionObject({
            options:_value
        })
      }

    
    return(
    <Fragment>
        <Grid container spacing={4} className="mb-2">
            <Grid item xs={8} lg={8} md={8}>
                <TextField
                    select
                    label="Select Emotions"
                    value={type}
                    fullWidth={true}
                    variant="outlined" 
                    size="small"
                    onChange={(event) => {handelTypeChange(event.target.value)}}
                    >
                    {dropObj.map((option, index) => (
                            <MenuItem key={option.id} value={option.id}>
                            {option.name}
                            </MenuItem>
                        ))}
                </TextField>
            </Grid>
                <Grid item xs={3} lg={3} md={3}>
                    <Button variant="outlined" color="primary"  onClick={addNewItem}>
                        <span className="btn-wrapper--icon">
                            Add Question
                        </span>
                    </Button>
                </Grid>
        </Grid>
        <div className="mb-3">
        <span>likert value</span>
        {typeValue.map((e, i) => (
                <Grid container spacing={0} key={i}>
                    <Grid item xs={6} lg={6} md={6}>
                    <TextField type="text"  size="small"  fullWidth={true} className="mb-1" variant="outlined" value={e} onChange={(event) => updateTypeItem(event.target.value, i)}   onBlur={submitObj} />
                    </Grid>
                </Grid>
              ))}
        </div>
        <div className="mb-3">
            Question
        {question.map((e, i) => (
                <Grid container spacing={0} key={i}>
                <Grid item xs={11} lg={11} md={11}>
                <TextField type="text"  size="small"  multiline fullWidth={true} className="mb-1" variant="outlined" value={e} onChange={(event) => updateQuestions(event.target.value, i)}  onBlur={submitObj} />
                </Grid>
                <Grid item xs={1} lg={1} md={1}>
                <IconButton  aria-label="delete" color="primary" onClick={removeQuestions.bind(null, i)}>
                    <Clear />
                  </IconButton>
                </Grid>
                </Grid>
              ))}
        </div>
    </Fragment>
    )
}

const LikertValidate = [];
    

export { LikertCreate, LikertRender, LikertValidate }