import React from 'react';
import Sidebar from './Sidebar';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { QuestionCreate } from '../../../../components/Questions';
import '../Manage.css'
import { 
  Grid,
  Paper,
  Button
 } from '@material-ui/core'; 

export default (props) => {
  return (
    
    <Grid container spacing={0} >
      <Grid item sm={3} md={3} lg={2} className="design-height-side-pannel">
        <Sidebar addQuestion={props.addQuestions} questions={props.questions} addQuestionTemplate={props.addQuestionTemplate}/>
      </Grid>
      <Grid item sm={9} md={9} lg={10} style={{backgroundColor: '#fafafa'}} className="design-Height-gird">
        <div className="side-overflow">
          <div className="d-block p-4 w-100">
            {props.questions.map((e, i) => (
              <QuestionCreate allQuestions={props.questions} key={e.feQId} data={e} index={i} moveQuestion={props.moveQuestion} modifyQuestion={props.modifyQuestion} removeQuestion={props.removeQuestion} />
            ))}
            <div className="d-block mt-4">
              <Button color="primary" variant="outlined" onClick={() => props.addQuestions(false)}>New Question</Button>
            </div>
          </div>
        </div>
      </Grid>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
    </Grid>
  )
}