import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Hidden, IconButton, AppBar, Box, Tooltip, Fab } from '@material-ui/core';

import { connect } from 'react-redux';

import {
  setSidebarToggle,
  setSidebarToggleMobile
} from '../../reducers/ThemeOptions';

import { ExitToApp, MenuRounded, MenuOpenRounded } from '@material-ui/icons';

const Header = props => {
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const {
    setSidebarToggle,
    sidebarToggle
  } = props;

  return (
    <Fragment>
      <div className="d-block w-100 d-md-none mt-1 pr-2" style={{position: "fixed", backgroundColor: '#ffffff50'}}>
        <IconButton className="float-right" size="small" color="primary" aria-label="logout" onClick={props.logout}>
          <ExitToApp />
        </IconButton>
      </div>
      <AppBar
        color="secondary"
        className={clsx('app-header', { 
          'app-header-submit': props.surveySubmit === true
         })}
        position={'fixed'}
        elevation={3}>
        <div
          className={clsx('app-header-logo', {
            'app-header-logo-close': sidebarToggle,
            'app-header-logo-open': !sidebarToggle
          })}>
          <Box
            className="app-logo-wrapper justify-content-between"
            title="Enumerate">
            {sidebarToggle ? null : (
              <Fragment>
                <Hidden smDown>
                  <Link to="/Home" className="app-logo-link">
                    <div className="app-logo-leftbox"></div>
                    <div className="app-logo-rightbox"></div>
                  <Box className="app-logo-text" >
                    <span style={{color:'whitesmoke'}}>ENUMERATE.AI</span>
                  </Box>
                  </Link>
                </Hidden>
              </Fragment>
            )}
            <Box
              className={clsx('btn-toggle-collapse', {
                'btn-toggle-collapse-closed': sidebarToggle
              })}>
              <Tooltip title="Toggle Sidebar" placement="right">
                <IconButton
                  color="inherit"
                  onClick={toggleSidebar}
                  size="medium"
                  className="btn-inverse">
                  {sidebarToggle ? (
                    <MenuRounded />
                  ) : (
                    <MenuOpenRounded />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </div>
        <Box className="app-header-toolbar justify-content-end">
          <Box className="d-flex align-items-center">
            <Box>
              <Tooltip title="Logout" placement="right">
                <IconButton
                  color="inherit"
                  onClick={props.logout}
                  size="medium">
                  <ExitToApp />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);