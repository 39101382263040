import React, { Fragment, useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Grid,
  Box,
  Typography,
  Dialog,
  Tabs,
  Tab,
  Card,
  Button,CircularProgress,
  TextField
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import styled from 'styled-components';
import { Survey } from '../../../../Store/ApplicationStore';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};


function CreateDialog(props) {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleBreadcrumb,
    pageTitleIconBox,
    pageTitleDescription
  } = props;

  

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let pptData = []
  let excelData = []
  try{
    pptData  = props.surveyData.pptDownload.values
    excelData = props.surveyData.excelDownload.values
  }
  catch{
    
  }
  const [pptList, setPPTList] = useState(pptData)
  const [excelList, setExcelList] = useState(excelData)
  const [excelProgressbar, setExcelprogressbar] = useState(false)
  const [pptProgressbar, setPptProgressbar] = useState(false)

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };


  const generateExcel = () =>{
    setExcelprogressbar(true)
    let Data = {
      'batchId': props.surveyId 
    }
    let _excelList = excelList
    Survey.getRawFullData(Data).then((e)=>{
      if (e.status === 'success'){
      _excelList.push(e.value)
      setExcelList(_excelList)
      const link = document.createElement('a');
      link.href = e.value[1];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      }
      setExcelprogressbar(false)
    })
  }

  const generatePPT = () =>{
    setPptProgressbar(true)
    let filter = {
      "age": 0,
      "ageOffset": 0,
      "batchId": props.surveyId,
      "city": [],
      "count": 0,
      "country": [],
      "gender": [],
      "offset": 0,
      "state": [],
  
    };
    filter.age = props.filterData.age.min ?? 0;
    filter.ageOffset = props.filterData.ageOffset ?? 0
    filter.gender = props.filterData.gender ?? [];
    filter.city = props.filterData.cities ?? [];
    filter.state = props.filterData.states ?? [];
    filter.country = props.filterData.country ?? [];
    let _pptList = pptList
    Survey.getPPtData(filter).then((e)=>{
      if (e.status === 'success'){
        _pptList.push(e.value)
        setPPTList(_pptList)
        const link = document.createElement('a');
        link.href = e.value[1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setPptProgressbar(false)
    })

  }

  const TableContentPPT = () => {
    return (
      <Fragment>
        { 
            pptData.map((data,idx) => (
                
            <Fragment key={idx}>
                <tr>
                <td className="text-center">
                    {data[0]}
                </td>
    
                <td className="text-center">
                <a href={data[1]} download  className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary">download</a>
                </td>
                
            </tr>
        </Fragment>   
        ))
  
        }
      </Fragment>);
}
const TableContentExcel = () => {

  return (
    <Fragment>
      { 
          excelData.map((data,idx) => (
              
          <Fragment key={idx}>
              <tr>
              <td className="text-center">
                  {data[0]}
              </td>
  
              <td className="text-center" style={{minWidth: '30px'}}>
              <a href={data[1]} download  className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary">download</a>
              </td>
              
          </tr>
      </Fragment>   
      ))

      }
    </Fragment>);
}




  return (
    <Fragment>   
        {!open && (
      <div className="d-flex align-items-center">
          <Button 
                    onClick={toggle1} 
                    // size="small"
                    // variant="outlined"
                    color="primary"
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary">
                    Download
        </Button>
      <Dialog scroll="body" maxWidth="lg" open={modal1} onClose={toggle1} >
        <Grid container spacing={0} style={{minWidth:'450px'}}>
          <Grid item xs={12} lg={12}>
            <div className="bg-white ">
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={handleChange}>
                <Tab className="py-3" label="PPT" />
                <Tab className="py-3" label="raw Data" />

              </Tabs>
              <TabPanel value={value} index={0}>
                    <Fragment>
                          <Grid container spacing={10} justify='center'>
                                  <Grid item xs={12} md={8} lg={10} >
                                  <Card className="card-box mb-4">
                                  <div className="table-responsive">
                                  <table className="table  table-hover table-bordered text-nowrap mb-0">
                                      <thead className="thead-light">
                                      <tr>
                                          <th className="text-center" style={{ width: '50%' }}>Date</th>
                                          <th  className="text-center" style={{ width: '50%'}}>DownloadLink</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                          <TableContentPPT/>
                                      </tbody>
                                  </table>
                                  </div>
                                  
                              </Card>
                          </Grid>
                        </Grid>
                      </Fragment>
                      <Grid container justify = "center" className="mt-2">
                          <Button variant="contained" color="secondary"  onClick={generatePPT}>Generate PPT</Button>
                          
                            { pptProgressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
                      </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                    <Fragment>
                          <Grid container spacing={10} justify='center'>
                                  <Grid item xs={12} md={8} lg={10} >
                                  <Card className="card-box mb-4">
                                  <div className="table-responsive">
                                  <table className="table  table-hover table-bordered text-nowrap mb-0">
                                      <thead className="thead-light">
                                      <tr>
                                          <th className="text-center" style={{ width: '50%' }}>Date</th>
                                          <th  className="text-center" style={{ width: '50%'}}>DownloadLink</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                        <TableContentExcel/>
                                      </tbody>
                                  </table>
                                  </div>
                                  
                              </Card>
                          </Grid>
                        </Grid>
                      </Fragment>
                      <Grid container justify = "center" className="mt-2">
                      <Button variant='contained' color="secondary"  onClick={generateExcel}>Generate Excel</Button>
                        
                        { excelProgressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
                  
                      </Grid>
              </TabPanel>
            </div> 
          </Grid>
        </Grid>
      </Dialog>
    </div>
  )}
  </Fragment>
  );
}
const mapStateToProps = state => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(CreateDialog);
