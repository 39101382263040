import React, { useState, Fragment } from 'react';
import { Button } from '@material-ui/core';
import Config from './Config';
import { ThemeProvider } from '@material-ui/core/styles';
import { CircularProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
export default (props) => {

  let EmotionsList = [];
  try {
    EmotionsList = props.questionObject.options || [];
  }
  catch (err) {}
  const [emotions, setEmotions] = useState([]);
  const [progressbar, setProgressbar] = useState(false);
  const [isAnswered,setIsAnswered] = useState(false)
  const selectEmotion = (emt) => {
    let newEmotions = [ ...emotions ];
    if (props.questionObject.multiple) {
      let index = newEmotions.indexOf(emt);
      if (index >= 0) {
        newEmotions.splice(index, 1);
      }
      else {
        newEmotions.push(emt);
      }
    }
    else {
      newEmotions = [emt];
    }
    setEmotions(newEmotions);
    if (!props.preview) {
      props.save({
        value: newEmotions
      })
    }
  }

  const submitAnswer = () => {
    if (emotions.length === 0 )
    {
      setIsAnswered(true)
    }
    else{
      setIsAnswered(false)
      setProgressbar(true)
      props.submit({
        value: emotions
      })
    }
  }

  return (
    <Fragment>
      <ThemeProvider theme={props.theme.pallet}>
        <div className="align-content-center justify-content-between" style={{whiteSpace: "initial"}}>
          {EmotionsList.map((e) => (
            <Button key={e} className="m-3" color={props.theme.color} variant={emotions.indexOf(e) >= 0 ? "contained" : "outlined"} onClick={selectEmotion.bind(null, e)}>
              <div className="d-flex flex-column align-item-center">
                <img src={Config.emotions[e].icon} style={{width: '5rem', height: '5rem'}} />
                <span className="mt-2" 
                style={{fontSize:props.theme.componentFontSize, color:props.theme.fontColor, fontFamily:props.theme.fontFamily}} >{Config.emotions[e].text}</span>
              </div>
            </Button>
          ))}
        </div>
        {isAnswered ? <div>
          <Alert severity="error">Please Answer </Alert>
          </div> : null}
        <div className="d-block">
          {props.preview ? null : (<Button className="mt-3" color={props.theme.color} variant={props.theme.buttonVariant} onClick={submitAnswer}>Submit</Button>)}
          { progressbar ? <CircularProgress style={{marginBottom:'-15px', marginLeft:'15px'}}/> : null}
        </div>
      </ThemeProvider>
    </Fragment>
  )
}