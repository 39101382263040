import React, { Fragment, useRef } from 'react';
import { FormControl, FormControlLabel, Radio, Checkbox, TextField, IconButton, Button, Tooltip, Grid } from '@material-ui/core';
import { Clear, Image, Add } from '@material-ui/icons';
import IosImagesOutline from 'react-ionicons/lib/IosImage'
export default (props) => {
  var values = props.questionObject.options || [];
  var fileRef = useRef();
  var selectedOption = null;

  function updateText(i, event) {
    let val = event.target.value;
    values[i] = val;
    props.updateQuestionObject({
      options: values
    });
  }

  function removeItem(i) {
    let newValue = [ ...values ];
    newValue.splice(i, 1);
    props.updateQuestionObject({
      options: newValue
    })
  }

  function openFileLoader(i) {
    selectedOption = i;
    fileRef.current.click();
  }

  function imageLoad() {
    if (fileRef.current.files.length > 0 && fileRef.current.files[0].name) {
      values[selectedOption] = {
        file: null,
        url: "",
        urlError: false,
        finUrl: ""
      }
      let fileName = fileRef.current.files[0].name.replace(/\s+/g,"_");
      values[selectedOption]["file"] = new File([fileRef.current.files[0]] , fileName, { type: fileRef.current.files[0].type });
      values[selectedOption]["finUrl"] = URL.createObjectURL(values[selectedOption]["file"]);
      props.updateQuestionObject({
        options: values
      })
    }
  }
  
  function updateURL(i, event) {
    values[i] = {
      file: null,
      url: event.target.value,
      urlError: false,
      finUrl: event.target.value
    }
    props.updateQuestionObject({
      options: values
    })
  }

  function addNewItem() {
    if (props.content == "text") {
      values.push("");
    }
    else {
      values.push({
        file: null,
        url: "",
        urlError: false,
        finUrl: "",

      });
    }
    props.updateQuestionObject({
      options: values
    })
  }

  let sel = null;
  if (props.type == 'single') {
    sel = <Radio checked={false} />
  }
  else {
    sel = <Checkbox checked={false} />
  }

  return (
    <div>
      <FormControl className="w-100">
        {values.map((e, i) => {
          if (props.content == "text") {
            return (
              <FormControlLabel className="d-flex align-items-center" key={i} control={sel} label={
                <Fragment>
                <Grid container spacing={2}>
                        <Grid item xs={11} md={11} l={11} >
                        <TextField multiline value={e} fullWidth onChange={updateText.bind(null, i)} variant="standard"  variant="outlined" size="small"/>
                        </Grid>
                        <Grid item xs={1} md={1} l={1} >
                        <IconButton aria-label="delete" color="primary" onClick={removeItem.bind(null, i)}>
                    <Clear />
                  </IconButton>
                        </Grid>
                  </Grid>
                </Fragment>
              } />
            )
          }
          else {
            return (
              <FormControlLabel className="mt-2 mb-2" key={i} control={sel} label={
                <Fragment>
                  <input type="file" ref={fileRef} accept="image/*" style={{display: 'none'}} onChange={imageLoad} />
                  <div className="d-flex justify-content-between">
                    {e.finUrl ? <img src={e.finUrl} className="img-fluid rounded mr-3 shadow-sm" style={{width: '5rem', height: '5rem'}} /> : <IosImagesOutline style={{width: '5rem', height: '5rem'}} color="var(--primary)"/> }
                      <Grid container spacing={2}>
                        <Grid item xs={11} md={10} l={11} >
                        <Button variant="outlined" color="primary" className="mb-1" onClick={openFileLoader.bind(null, i)}>Image Upload</Button>
                      <TextField variant="outlined" className="mb-1" multiline fullWidth size="small" type="url" placeholder="Image URL" value={e.url || ""} onChange={updateURL.bind(null, i)} />
                      </Grid> 
                        <Grid item xs={1} md={1} l={1} >
                          <IconButton aria-label="delete" color="primary" onClick={removeItem.bind(null, i)}>
                            <Clear />
                          </IconButton>
                        </Grid> 
                      </Grid>
                  </div>
                </Fragment>
              } />
            )
          }
        })}
      </FormControl>
      <div className="d-block">
        <Tooltip title="Add Item">
          <IconButton aria-label="add" onClick={addNewItem}>
            <Add />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}