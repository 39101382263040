import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';


export default (props) => {
    let data = props.values;

    let overAllData = []
    if (data != null){
        for (let [key, value] of Object.entries(data)) {
            let title = key
            let categories = [];
            let values = [];
            for (let [x, y] of Object.entries(value)) {
                    categories.push(x);
                    values.push(y);
                }
            const options = {
                chart: {
                    type: 'bar',
                    toolbar: {
                    show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                    horizontal: true
                    }
                },
                xaxis: {
                    categories: categories
                }
                };
            const series = [
            {
                data: values
            }
            ];
    
            overAllData.push({
                title : title,
                options: options,
                series: series
            })
            
          }
    }
    
    
      const TableContent = () => {
        return (
            <Fragment>
            { 
                overAllData.map((data,idx) => (
                    
                <Fragment key={idx}>
                    <div className="mb-1 mt-1">{ data.title }</div>
                    <div style={{height: 350}}>
                    <Chart options={data.options} series={data.series} height='100%' type="bar" />
                    </div>
                </Fragment>
                ))
            }
            </Fragment>
        )
      }


  return (
    <Fragment> 
        <TableContent/>
    
    </Fragment>
    
  );
}