import React, { useState, Fragment } from 'react';
import { 
  TextField,
  FormControlLabel,
  Switch
} from '@material-ui/core';

export default (props) => {
  let initMinValue = props.questionObject.min || "";
  let initMaxValue = props.questionObject.max || "";
  const [range, setRange] = useState(initMinValue != "" || initMaxValue != "");
  const [min, setMin] = useState(initMinValue);
  const [max, setMax] = useState(initMaxValue);

  function updateValue(key) {
    let newValue = "";
    let oldValue = "";
    if(key == 'min') {
      newValue = min;
      oldValue = props.questionObject.min;
    }
    else if(key == 'max') {
      newValue = max;
      oldValue = props.questionObject.max;
    }
    if (newValue !== oldValue) {
      let obj = {};
      obj[key] = newValue;
      props.updateQuestionObject(obj);
    }
  }

  return (
    <Fragment>
      {props.range ? (
        <div className="d-block">
          <FormControlLabel
            control={
              <Switch
                checked={range}
                onChange={(event) => setRange(event.target.checked)}
                name="checkedB"
                color="primary"
              />
            }
            label="Set range for valid answers"
          />
        </div>
      ) : null}
      {props.range && range ? (
        <div className="d-block">
          <TextField   variant="outlined" size="small"
            InputLabelProps={props.type.indexOf('date') >= 0 || props.type.indexOf('time') >= 0 ? { shrink: true } : {}}
            placeholder=""
            className="mr-4 w-25 mb-1"
            label="Minimum Value"
            type={props.type} 
            value={min} 
            onChange={(event) => {setMin(event.target.value)}} 
            onBlur={updateValue.bind(null, 'min')}
          />
          <TextField variant="outlined" size="small"
            InputLabelProps={props.type.indexOf('date') >= 0 || props.type.indexOf('time') >= 0 ? { shrink: true } : {}}
            placeholder="" 
            className="w-25 mb-1"
            label="Maximum Value" 
            type={props.type} 
            value={max} 
            onChange={(event) => {setMax(event.target.value)}} 
            onBlur={updateValue.bind(null, 'max')}
          />
        </div>
      ) : null}
    </Fragment>
  )
}