import React, { Fragment } from 'react';
import Config from './Config';

export default (props) => {

  return (
    <Fragment>
      {(Config[props.id]!= null) ? Config[props.id]["dashboard"]({
        values: props.values,
        media:props.media,
        analyseStatus: props.analyseStatus
      })
    :null}
    </Fragment>
  )
}