const Validation = [
  {
    id: "contains",
    validate: (answer, value) => {
      let lowerAns = answer.value[0].toLowerCase();
      let val = value.toLowerCase();
      if (lowerAns.indexOf(val) >= 0) {
        return true;
      }
      return false;
    }
  },
  {
    id: "equals",
    validate: (answer, value) => {
      let lowerAns = answer.value[0].toLowerCase();
      let val = value.toLowerCase();
      if (lowerAns == val) {
        return true;
      }
      return false;
    }
  }
];

export default Validation;