import React, { useState, Fragment } from 'react';
import { Button, IconButton, ListItem,Dialog, DialogTitle, DialogContent,
    DialogActions, CardContent,
     Divider ,TextField, Grid,   Box,Card,
     Tooltip,} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

export default function BgImageDialog (props)  {
  return (
    <Fragment>
      <Dialog
        open={props.openBgDialog}
        onClose={props.handleBGDialigClose}
        scroll={props.scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Background Images</DialogTitle>
        <DialogContent dividers={props.scroll === 'paper'}>
          <div className="d-block mb-4 pb-3">  
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} l={4} style={{cursor: "pointer"}} 
              onClick={(e)=>props.handleBackGroundImageChange( e,"None")}>
              <CardContent className="p-3" >
                <div style={{width:"100px", height:"80px", border:"1px solid black"}}></div>
              </CardContent>
            </Grid>
            <Grid item xs={4} md={4} l={4} style={{cursor: "pointer"}} 
              onClick={(e)=>props.handleBackGroundImageChange( e,"https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/fog-3622519_1920.jpg")}>
              <CardContent className="p-3" >
                <img alt="..." src="https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/fog-3622_50_40.jpg"/>
              </CardContent>
            </Grid>
            <Grid item xs={4} md={4} l={4}  style={{cursor: "pointer"}}
            onClick={(e)=>props.handleBackGroundImageChange( e,"https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-2.jpg")}>
              <CardContent className="p-3">
                <img alt="..." src="https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-2_50_40.jpg"/>
              </CardContent>
            </Grid>    
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} l={4}  style={{cursor: "pointer"}}
            onClick={(e)=>props.handleBackGroundImageChange( e,"https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-3.jpg")}>
              <CardContent className="p-3">
                <img alt="..." src="https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-3_50_40.jpg"/>
              </CardContent>
            </Grid>
            <Grid item xs={4} md={4} l={4}  style={{cursor: "pointer"}}
            onClick={(e)=>props.handleBackGroundImageChange( e,"https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-4.jpg")}>
              <CardContent className="p-3">
                <img alt="..." src="https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-4_50_40.jpg"/>
              </CardContent>
            </Grid>
            <Grid item xs={4} md={4} l={4}  style={{cursor: "pointer"}} 
            onClick={(e)=>props.handleBackGroundImageChange( e,"https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-5.jpg")}>
              <CardContent className="p-3">
                <img alt="..." src="https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-5_50_40.jpg"/>
              </CardContent>
            </Grid>    
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} l={4}  style={{cursor: "pointer"}}
            onClick={(e)=>props.handleBackGroundImageChange( e,"https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-6.jpg")}>
              <CardContent className="p-3">
                <img alt="..." src="https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-6_50_40.jpg"/>
              </CardContent>
            </Grid>
            <Grid item xs={4} md={4} l={4}  style={{cursor: "pointer"}} 
            onClick={(e)=>props.handleBackGroundImageChange( e,"https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-7.jpg")}>
              <CardContent className="p-3">
                <img alt="..." src="https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-7_50_40.jpg"/>
              </CardContent>
            </Grid>
            <Grid item xs={4} md={4} l={4}  style={{cursor: "pointer"}}
              onClick={(e)=>props.handleBackGroundImageChange( e,"https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-8.jpg")}>
              <CardContent className="p-3">
                <img alt="..." src="https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-8_50_40.jpg"/>
              </CardContent>
            </Grid>    
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs={4} md={4} l={4}  style={{cursor: "pointer"}}
            onClick={(e)=>props.handleBackGroundImageChange( e,"https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-1.jpg")}>
              <CardContent className="p-3">
                <img alt="..." src="https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-1_50_40.jpg"/>
              </CardContent>
            </Grid>
            <Grid item xs={4} md={4} l={4}  style={{cursor: "pointer"}}
            onClick={(e)=>props.handleBackGroundImageChange( e,"https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-9.jpg")}>
              <CardContent className="p-3">
                <img alt="..." src="https://enumerate-images-mumbai.s3.ap-south-1.amazonaws.com/bg/hero-9_50_40.jpg"/>
              </CardContent>
            </Grid>
            </Grid>

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleBGDialigClose} variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

    </Fragment>
  )
}
