import React, { Fragment, useRef } from 'react';
import QuestionList from './QuestionBank';
import {
  Card,
  CardContent,
  Grid,
  Box
} from '@material-ui/core';
export default (props) => {
  const videoRef = useRef(null) 
  const QuestionComponent = QuestionList[props.data.answerType]["render"]({
    questionObject: props.data.questionObject,
    answer: props.data.answer || {},
    preview: props.preview,
    submit: props.submit,
    save: props.save,
    answerType: props.data.answerType,
    videoRef:videoRef,
    theme: props.theme
  });
  return (
    <Fragment>
      {props.data.questionUrl == "" ? (
        <Card className="card-box mt-2 mb-4" variant="outlined" style={{ backgroundColor: props.theme.componentBgColorRgba , opacity:props.theme.componentOpacity}}>
          <div className="card-header justify-content-between" style={{ backgroundColor: props.theme.componentBgColorRgba , opacity:props.theme.componentOpacity}}>
            <div className="font-size-lg d-flex align-items-end mr-1" style={{flex: 1, fontSize:props.theme.questionFontSize, fontFamily:props.theme.fontFamily}}>
            { props.questionPreview ?
            <Fragment>
              <span className="text-black-50 pr-2">{ `#` + props.data.orderId + `:`}</span>
              <span className="text-black-100 pr-2">{props.data.questionText}</span> </Fragment>: 
            <span className="text-black-100 pr-2">{ `#` + props.data.orderId + `:` + props.data.questionText}</span> }
            </div>
          </div>
          <CardContent className="p-3" >
            {props.data.answerType ? QuestionComponent : null}
          </CardContent>
        </Card>
      ) : (
        <Card className="card-box mt-2 mb-4" variant="outlined" style={{ backgroundColor: props.theme.componentBgColorRgba, opacity:props.theme.componentOpacity}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="card-header justify-content-between" style={{ backgroundColor: props.theme.componentBgColor, opacity:props.theme.componentOpacity}}>
                <div className="font-size-lg d-flex align-items-end mr-1" style={{flex: 1, fontSize:props.theme.questionFontSize ,fontFamily:props.theme.fontFamily}}>
                  <span className="text-black-50 pr-2">{`#`}</span>
                  <span className="text-black-100 pr-2">{props.data.questionText}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={4} style={{borderRight:'1px solid #e0e0e2'}}>
              <div className="d-flex justify-content-center w-100 h-100 p-2" style={{maxWidth:'750px',maxHeight:'750px', margin: 'auto'}}>
                {props.data.questionTypeName.indexOf('image') >= 0 ? (
                  <img className="rounded opacity-9 img-fluid" src={props.data.questionUrl} />
                ) : (
                  <video className="rounded opacity-9 w-100" controls controlsList="nodownload" ref={videoRef}>
                    <source src={props.data.questionUrl} />
                  </video>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <Box className="p-3" s>
                {QuestionList.hasOwnProperty(props.data.answerType) ? QuestionComponent : null}
              </Box>
            </Grid>
          </Grid>
        </Card>
      )}
    </Fragment>
  )
}