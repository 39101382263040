import React, { useState, Fragment } from 'react';
import { Button ,Card, Grid, CardContent} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

export default function PageContainer (props)  {
  return (
    <Fragment>
      <ThemeProvider theme={props.theme.pallet}>
      <Grid container spacing={4} alignItems="center" justify="center">
            <Grid item xs={8} lg={8}>
                <Card className="mt-4" style={{ backgroundColor: props.theme.componentBgColorRgba, opacity: props.theme.componentOpacity}}>
                  <CardContent className="p-3">
                  {(props.type == "welcome")?
                  <div className="text-center">
                    {props.welcomePageTitle != null ? <h2 className="font-weight-bold mt-3" style={{fontSize:props.theme.questionFontSize, fontFamily:props.theme.fontFamily, color:props.theme.fontColor, whiteSpace:"pre-wrap"}}>{props.welcomePageTitle}</h2> :null}
                    {props.welcomePageText != null ?
                    <p style={{fontSize:props.theme.componentFontSize, fontFamily:props.theme.fontFamily, color:props.theme.fontColor, whiteSpace:"pre-wrap"}}>{props.welcomePageText}</p>: null}
                    {props.welcomePageSubText != null ?
                    <p style={{fontFamily:props.theme.fontFamily, color:props.theme.fontColor , whiteSpace:"pre-wrap", textAlign:"left"}} >{props.welcomePageSubText}</p> : null}
                    <Button className="mt-3" color={props.theme.color} variant={props.theme.buttonVariant} onClick={props.welcomeContinue}>Continue</Button>
                    </div>
                    :
                    <div className="text-center">
                    <h3 className="font-weight-bold mt-3" style={{fontSize:props.theme.questionFontSize, fontFamily:props.theme.fontFamily, color:props.theme.fontColor}}>{props.data}</h3>
                    </div>
                    } 
                  </CardContent>
                </Card>
            </Grid> 
        </Grid>
      </ThemeProvider>
    </Fragment>
  )
}

