import React, { useState, Fragment } from 'react';
import {
  Paper,
  Box,
  IconButton,
  Tooltip,
  ListItem,
  Divider  
} from '@material-ui/core';
import { 
  AccountBalance,
  List,
  PostAdd
} from '@material-ui/icons';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TabPanel from '../../../../components/TabPanel';
import QuestionList from '../../../../components/Questions/QuestionBank';
import QuestionTemplate from '../../../../components/Questions/QuestionTemplate';
import '../Manage.css'
export default (props) => {
  const [tab, setTab] = useState(0);

  return (
    <Paper
      className={clsx('app-sidebar-wrapper', {
        'app-sidebar-wrapper-close': false,
        'app-sidebar-wrapper-open': true,
        'app-sidebar-wrapper-fixed': false
      })}
      square
      open={true}
      elevation={3}>
      <div
        className={clsx({
          'app-sidebar-menu': true,
          'app-sidebar-collapsed': false
        })}>
        <div className="side-overflow">
          <Box bgcolor="white" p={0} m={0}>
            <Box className="app-sidebar-footer-wrapper">
              <ul className="app-sidebar-footer">
                <li>
                  <Tooltip arrow title="Question Bank">
                    <IconButton onClick={() => setTab(0)} color={tab == 0 ? "primary" : "default"}>
                      <AccountBalance />
                    </IconButton>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip arrow title="Question Template">
                    <IconButton onClick={() => setTab(1)} color={tab == 0 ? "primary" : "default"}>
                      <PostAdd />
                    </IconButton>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip arrow title="Question List">
                    <IconButton onClick={() => setTab(2)} color={tab == 1 ? "primary" : "default"}>
                      <List />
                    </IconButton>
                  </Tooltip>
                </li>
              </ul>
            </Box>
            <Box>
              <TabPanel value={tab} index={0}>
                {Object.keys(QuestionList).map((e, i) => (
                  <Fragment key={e}>
                    <ListItem button className="py-3 border-0" onClick={() => props.addQuestion(e)}>
                      <div className="align-box-row w-100">
                        <div className="mr-3">
                          <div className="bg-neutral-dark text-primary text-center font-size-xl d-40 rounded-sm">
                            {QuestionList[e]["icon"]}
                          </div>
                        </div>
                        <div>
                          <div className="font-weight-bold d-block opacity-8">
                            {QuestionList[e]["name"]}
                          </div>
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </TabPanel>
              <TabPanel value={tab} index={1}>
              {Object.keys(QuestionTemplate).map((e, i) => (
                  <Fragment key={e}>
                    <ListItem button className="py-3 border-0" onClick={() => props.addQuestionTemplate(QuestionTemplate[e]['object'])}>
                      <div className="align-box-row w-100" >
                          <div className="font-weight-bold d-block opacity-8" style={{textAlign:'center'}}>
                            {QuestionTemplate[e]["name"]}
                          </div>
                      </div>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </TabPanel>
              <TabPanel value={tab} index={2}>
                {props.questions.map((e, i) => (
                  <Fragment key={e.feQId}>
                    <ListItem button className="py-3 border-0">
                      <div className="align-box-row w-100">
                        <div className="mr-3">
                          <div className="bg-neutral-dark text-primary text-center font-size-xl d-40 rounded-sm">
                            {i+1}
                          </div>
                        </div>
                        <div className="font-weight-bold d-block opacity-8 text-truncate">
                          {e.questionText}
                        </div>
                      </div>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </TabPanel>
            </Box>
          </Box>
        </div>
      </div>
    </Paper>
  );
}