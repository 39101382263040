import React, { Fragment } from 'react';
import {
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Button,
  TextField,
  FormControl,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';


const Login = (props) => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [checked1, setChecked1] = React.useState(true);
  
    const handleChange1 = event => {
      setChecked1(event.target.checked);
    };
  
    const handleLogin = event => {
      props.login(email,password)
    };
  
    return (
            <Fragment>
                          <p className="font-size-lg mb-5 text-black-50">
                            Welcome back.
                            </p>
                            <Card className="mx-0 bg-secondary mt-0 w-100 p-0 mb-4 border-0 ">
                            
                               <div className="card-header d-block p-3 mx-2 mb-0 mt-2 rounded border-0">
                                <div className="text-muted text-center mb-3">
                                  <span className="font-size-md mb-0 text-black-50">Sign in </span>
                                </div>
                                {/* </div> */}
                                {/* <div className="text-center">
                                  <Button
                                    variant="outlined"
                                    className="mr-2 text-facebook">
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon
                                        icon={['fab', 'facebook']}
                                      />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Facebook
                                    </span>
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    className="ml-2 text-twitter">
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon
                                        icon={['fab', 'twitter']}
                                      />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Twitter
                                    </span>
                                  </Button>
                                </div>
                                </Card> */}
                              </div>
                              <CardContent className="p-3">
                              
                                <form className="px-5 pt-20" >
                                  <div className="mb-3">
                                    <FormControl className="w-100">
                                      <TextField
                                        variant="outlined"
                                        label="Email"
                                        fullWidth
                                        placeholder="Enter your Email"
                                        type="Text"
                                        value={email}
                                        onChange={(event) => {setEmail(event.target.value)}}
                                      />
                                    </FormControl>
                                  </div>
                                  <div className="mb-3">
                                    <FormControl className="w-100">
                                       <TextField
                                        variant="outlined"
                                        label="Password"
                                        fullWidth
                                        placeholder="Enter your password"
                                        type="password"
                                        value={password}
                                        onChange={(event) => {setPassword(event.target.value)}}
                                      />
                                    </FormControl>
                                  </div>
                                  <div className="w-100">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={checked1}
                                          onChange={handleChange1}
                                          value="checked1"
                                          color="primary"
                                        />
                                      }
                                      label="Remember me"
                                    />
                                  </div>
                                  { !props.isValidLogin ? <div className="w-100"><Alert severity="error"> Invalid Email or Password</Alert></div> : null }
                                  <div className="text-center">
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      size="large"
                                      className="my-2"
                                      onClick={handleLogin}>
                                      Sign in
                                    </Button>
                                  </div>
                                </form>
                              </CardContent>
                            </Card>
                          </Fragment>
    );
  };
  
  export default Login;