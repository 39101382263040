import React, { useState, Fragment ,useEffect} from 'react';
import { Button, Dialog, DialogTitle, DialogContent,
    DialogActions,  Grid,  TextField } from '@material-ui/core';

import Dropzone, {useDropzone} from 'react-dropzone'
import {Authorization, uploadFileAndLink} from '../../../../Store/ApplicationStore' 
export default function LogoDialog (props)  {

  const [fileUrl, setFileUrl] = useState((props.logo!="" && props.logo !="None")? props.logo : "")
  const [width, setWidth] = useState((props.logoWidth!="" )? props.logoWidth : "200")
  const [height, setHeight] = useState((props.logoHeight!="" )? props.logoHeight : "50")
  const [file, setFile] = useState(null)

  const onDrop =(files) =>{
    let fileName = files[0].name.replace(/\s+/g,"_");
    let newFile = new File([files[0]] , fileName , { type: files[0].type });
    let url = URL.createObjectURL(newFile)
    props.setLogo(url)
    setFileUrl(url)
    setFile(newFile)
  }
  const hadelCancel =() =>{
    props.setLogo("")
    setFileUrl("")
    setFile()
    props.handleLogoDialigClose()
  }


  async function hadelSave() {
    let header = Authorization.getHeader()
    let url = await uploadFileAndLink(header, props.id, 1, file);
    props.setLogo(url)
    setFileUrl(url)
    props.handleLogoSave(url , width, height)
    props.handleLogoDialigClose()
  }

  return (
    <Fragment>
    <Dialog
        open={props.openLogoDialog}
        onClose={props.handleLogoDialigClose}
        scroll={props.scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Add Logo</DialogTitle>
        <DialogContent dividers={props.scroll === 'paper'}>
          <Grid container spacing={2} alignItems="center" justify="center">
          <Grid item xs={12} lg={12} md={12} >
            <div className="dropzone">
                    <Dropzone
                        onDrop={onDrop}
                    >
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="dz-message">
                                    <div className="dx-text">
                                        Try dropping a Image Files.
                                    </div>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>
              </Grid>
              <Grid item xs={6} lg={6} md={6}>
                  <TextField
                  variant="outlined"
                  label="Width"
                  fullWidth
                  placeholder="Enter Width"
                  type="number"
                  value={width}
                  onChange={(event) => {setWidth(event.target.value)}}
                  />
                </Grid>
                <Grid item xs={6} lg={6} md={6}>
                    <TextField
                    variant="outlined"
                    label="Height"
                    fullWidth
                    placeholder="Enter Height"
                    type="number"
                    value={height}
                    onChange={(event) => {setHeight(event.target.value)}}
                />
              </Grid>
              {(fileUrl !== "")?
              <Grid item xs={8} lg={8} md={8} >
              <div className="card-img-wrapper" >
                   <img src={fileUrl}  className="card-img-top rounded" alt="..." style={{maxWidth: width +"px", maxHeight: height +"px"}}/>
               </div>
            </Grid> : null }
              
          </Grid>
        </DialogContent>
        <DialogActions>
        <Button onClick={hadelSave} color="primary" variant="outlined" color="secondary">
            save
          </Button>
          <Button onClick={ hadelCancel } color="primary" variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

    </Fragment>
  )
}
