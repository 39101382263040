import React, { Fragment, useState } from 'react';
import { FormControlLabel, TextField, IconButton, Switch, Tooltip,Grid } from '@material-ui/core';
import { Clear, Add } from '@material-ui/icons';

export default (props) => {
  const [separatedValues, setSeparatedValues] = useState(true);
  const [separator, setSeparator] = useState(",");
  let inititalValues = props.questionObject.options || [];
  const [values, setValues] = useState(inititalValues);

  const submitDropDown = () => {
    props.updateQuestionObject({
      options: values
    })
  }

  const updateItem = (text, index) => {
    let newValue = [...values];
    newValue[index] = text;
    setValues(newValue);
  }

  const removeItem = (index) => {
    let newValue = [...values];
    newValue.splice(index, 1);
    setValues(newValue);
    submitDropDown();
  }

  const addNewItem = () => {
    let newValue = [...values];
    newValue.push("");
    setValues(newValue);
  }

  return (
    <Fragment>
      <div className="d-block mt-3 mb-3">
        <FormControlLabel
          control={
            <Switch
              checked={separatedValues}
              onChange={(event) => setSeparatedValues(event.target.checked)}
              name="checkedB"
              color="primary"
            />
          }
          label="Use separator for bulk values"
        />
      </div>
      {separatedValues ? (
        <Fragment>
          <Grid container spacing={4}>
            <Grid item xs={2} lg={2}>
            <TextField  variant="outlined" size="small"
              label="Set Separator"
              type="text"
              value={separator}
              onChange={(event) => {
                setSeparator(event.target.value)
              }}
            />
            </Grid>
            <Grid item xs={10} lg={10}>
            <TextField
             variant="outlined" size="small"
              fullWidth
              label="Values"
              type="text"
              multiline
              value={values.join(separator)}
              onChange={(event) => {
                let arr = event.target.value.split(separator);
                setValues(arr);
              }}
              onBlur={submitDropDown}
            />
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Fragment>
          {values.map((e, i) => (
            <div className="d-flex align-items-center">
              <TextField type="text"  size="small"  className="mb-1" variant="outlined" value={e} onChange={(event) => updateItem(event.target.value, i)} onBlur={submitDropDown} />
              <IconButton className="ml-4" aria-label="delete" color="primary" onClick={removeItem.bind(null, i)}>
                <Clear />
              </IconButton>
            </div> 
          ))}
          <div className="d-block">
            <Tooltip title="Add Item">
              <IconButton aria-label="add" onClick={addNewItem}>
                <Add />
              </IconButton>
            </Tooltip>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}