const Validation = {
  "text": [
    {
      id: "contains",
      validate: (answer, value) => {
        let val = value.toLowerCase();
        for (let i = 0; i < answer.value.length; i++) {
          let lowerAns = answer.value[i].toLowerCase();
          if (lowerAns.indexOf(val) >= 0) {
            return true;
          }
        }
        return false;
      }
    },
    {
      id: "equals",
      validate: (answer, value) => {
        let lowerAns = answer.value[0].toLowerCase();
        let val = value.toLowerCase();
        if (lowerAns == val && answer.value.length == 1) {
          return true;
        }
        return false;
      }
    }
  ],
  "image": []
}

export default Validation;