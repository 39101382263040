import React, { Fragment, useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  TextField, Button
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Authorization, Survey } from '../../../../Store/ApplicationStore';
import { Category } from '@material-ui/icons';


export default function BuyPanel (props) {
    const genderObjList= [
        {
            id:0,
            name: "Female"
        },
        {
          id:1,
          name: "Male"
        },
        {
          id:2,
          name: "Others"
      },
        {
          id:-2,
          name: "All"
      }
    ]
    const LanguageObjList= [
      {
          id:"Hindi",
          name: "Hindi"
      },
      {
        id:"English",
        name: "English"
    }
    ]

    const surveyTypes = {
      1:"Simple Survey",
      2:"Ad Testing",
      3:"Video Conference"
    }
    const getCountryObj = (data)=>{
      let arr = []
      if (data!= null && data.length !=0)
      {
        data.forEach(e => {
        arr.push(
          {id: e, name: e}
          )
        });
      }
      return arr
    }
    const getGender =(ids)=>{
      let arr = []
      ids.map(e => {
        arr.push(genderObjList[genderObjList.findIndex(x => x.id === parseInt(e))])
      })
      return arr
    }


    const changeDate =(data)=>{
      if (data == null){
       return null
      }
      let b = data.split("-")
      let c = b[1] + '/' + b[2] + '/' + b[0]
      return c
    }
  
    const getDate = ()=>{
      let now = new Date();
      let out = null
      if (now.getMonth() == 11) {
          out= 1 + '/' + now.getDate() + '/' + now.getFullYear() + 2; 
      } else {
          out = now.getMonth() + 2 + '/' + now.getDate() + '/' + now.getFullYear() ; 
      }
      return(out)
    }
 
    const [expectedEndDate, setExpectedEndDate] = useState(changeDate(props.surveyData.expectedEndDate) ?? getDate());
    const [extraInfo, setExtraInfo] = React.useState(props.surveyData.pannelExtraInfo ?? "")
    const [response, setResponse] = React.useState(props.surveyData.expectedesponses   ?? 2500);
    const [malePercent,setMalePercent] = React.useState( (props.surveyData.pannelGenderBreakup != null) ? props.surveyData.pannelGenderBreakup.male : 40);
    const [femalPercent,setFemalPercent] = React.useState( (props.surveyData.pannelGenderBreakup != null) ? props.surveyData.pannelGenderBreakup.female : 40);
    const [othersPercent,setOthersPercent] = React.useState( (props.surveyData.pannelGenderBreakup != null) ? props.surveyData.pannelGenderBreakup.others : 20);
    const [minAge, setMinAge] = React.useState((props.surveyData.ageGroup != null) ? props.surveyData.ageGroup[0] : 10);
    const [maxAge, setMaxAge] = React.useState((props.surveyData.ageGroup != null) ? props.surveyData.ageGroup[1] : 60);
    const [country, setCountry] = React.useState(getCountryObj(props.surveyData.countryList));
    const [gender, setGender] = React.useState(props.surveyData.pannelGender != null ? getGender(props.surveyData.pannelGender) :[genderObjList[0], genderObjList[1]]);
    const [language, setLanguage] = React.useState(LanguageObjList[LanguageObjList.findIndex(x => x.id === props.surveyData.language)]);
    const [countryList, setCountryList] = React.useState([]);
    const [industryList, setIndustryList] = React.useState([]);
    const [industry, setIndustry] = React.useState([]);
    const [category, setCategory] = React.useState([]);
    const [allCategoryList, setAllCategoryList] = React.useState([]);
    const [categoryList, setCategoryList] = React.useState([]);
    const [isPannelRequested, setIsPanelRequested] = React.useState(props.surveyData.pannelRequested ?? false);
    
    useEffect(() => {
      if(!industryList.length){
        Survey.getIndustryCategoryProduct().then(response => {
          if(response.status === true){
            setIndustryList(response.industry)
            setAllCategoryList(response.category)
            if (props.surveyData.industryId != null){
              setIndustry(response.industry[response.industry.findIndex(x=>x.id === parseInt(props.surveyData.industryId))])
            }
            if (props.surveyData.categoryId != null){
              setCategory(response.category[response.category.findIndex(x=>x.id === parseInt(props.surveyData.categoryId))])
            }
            
          } 
        })
      }
    },[industryList, allCategoryList]);
    
    useEffect(() => {
      if (!countryList.length){
          Authorization.getCountry().then(response => {
              setCountryList(response)
          })
        }
      
    },[countryList]);
      
      const handleGenderChange = (values) => {
        setGender(values)
      };
    
      const handleCountryChange = (event, values) => {
        let arr = []
        values.forEach(e => {
          arr.push(e.id)
        });
        setCountry(values)
      };

      const handleIndustryChange = (event, values) => {
        setIndustry(values)
          if (values!= null){
            let arr = []
            allCategoryList.map((e ,id) => {
                if(values.id === e.industryId){
                    arr.push(e)
                }
            })
            setCategoryList(arr)
          }
      };
      
    
      const requestPannel = () =>{
        setIsPanelRequested(true)
        let _surveyData = props.surveyData
        _surveyData.numberOfRecipents = response
        _surveyData.countryList = Object.values(country).map((key) => key['name'])
        _surveyData.ageGroup = [minAge ,maxAge]
        _surveyData.expectedEndDate = expectedEndDate
        _surveyData.pannelGender = gender != null ? Object.values(gender).map((key) => key['id']):[]
        _surveyData.pannelGenderBreakup = {"male":malePercent, "female":femalPercent, "others":othersPercent}
        _surveyData.pannelExtraInfo = extraInfo
        _surveyData.pannelRequested = true
        _surveyData.industryId = industry!=null ? industry['id'] : 0
        _surveyData.categoryId = category != null ? category['id']: 0
        _surveyData.language = language != null ? language['name'] : ""
        props.editSurvey(_surveyData)
        Survey.updateExistingSurvey(_surveyData)
      }
  
  return (
    <Fragment>
        <Grid container spacing={4} alignItems="center" justify="center">
            <Grid item xs={8} lg={8}>
                <Card className="mt-4 card-border-top border-primary">
                  <CardContent className="p-3 ">
                  <div className="justify-content-between mb-4">
                        <div className="mb3">
                            <TextField
                                variant="outlined"
                                label="Required Response"
                                fullWidth
                                placeholder="Enter total required response"
                                type="number"
                                value={response}
                                onChange={(event) => {setResponse(event.target.value)}}
                                />
                        </div>
                        <div className="mb3">
                            <Autocomplete className="my-3"
                              id="gender"
                              size="medium"
                              multiple
                              options={genderObjList}
                              getOptionLabel={option => option.name}
                              defaultValue={gender}
                              onChange={(event, values) => values ? handleGenderChange(values): null}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Gender"
                                  placeholder="Gender"
                                  fullWidth
                                />
                              )}
                            />
                        </div>
                        <div className="mb-3">
                        <Grid container spacing={2} alignItems="center" justify="center">
                            <Grid item xs={4} lg={4}>
                                <TextField
                                variant="outlined"
                                label="Male %"
                                fullWidth
                                placeholder="Required Male %"
                                type="number"
                                value={malePercent}
                                onChange={(event) => {setMalePercent(event.target.value)}}
                                />
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <TextField
                                variant="outlined"
                                label="Female %"
                                fullWidth
                                placeholder="Required Male %"
                                type="number"
                                value={femalPercent}
                                onChange={(event) => {setFemalPercent(event.target.value)}}
                            />
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <TextField
                                variant="outlined"
                                label="Others %"
                                fullWidth
                                placeholder="Required Others %"
                                type="number"
                                value={othersPercent}
                                onChange={(event) => {setOthersPercent(event.target.value)}}
                            />
                            </Grid>
                        </Grid>
                        </div>
                        <div className="mb-3">
                        <Grid container spacing={4} alignItems="center" justify="center">
                            <Grid item xs={6} lg={6}>
                                <TextField
                                variant="outlined"
                                label="Age Min"
                                fullWidth
                                placeholder="Enter Starting Age"
                                type="number"
                                value={minAge}
                                onChange={(event) => {setMinAge(event.target.value)}}
                                />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <TextField
                                variant="outlined"
                                label="Age Max"
                                fullWidth
                                placeholder="Enter End Age"
                                type="number"
                                value={maxAge}
                                onChange={(event) => {setMaxAge(event.target.value)}}
                            />
                            </Grid>
                        </Grid>
                        </div>
                        <div className="mb-3">
                        <TextField
                          label="Extra info"
                          multiline
                          fullWidth
                          rows="4"
                          placeholder="Add any more Information Required"
                          variant="outlined"
                          value={extraInfo}
                          onChange={(event) => {setExtraInfo(event.target.value)}}
                        />
                        </div>
                        
                        <div className="mb3">
                            <Autocomplete className="my-3"
                                  multiple
                                  id="country-select"
                                  size="medium"
                                  options={countryList}
                                  getOptionLabel={option => option.name}
                                  onChange={handleCountryChange}
                                  value={country}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Country"
                                      placeholder="targeted Country"
                                      fullWidth
                                    />
                                  )}
                                />
                        </div>
                        <div className="mb3" >
                          <Autocomplete className="my-3"
                                id="industry-select"
                                size="medium"
                                options={industryList}
                                value={industry}
                                getOptionLabel={option => option.name}
                                onChange={handleIndustryChange}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Industry"
                                    placeholder="select a industry"
                                    fullWidth
                                  />
                                )}
                              />
                        </div>
                        <div className="mb3" >
                          <Autocomplete className="my-3"
                                id="category-select"
                                size="medium"
                                value={category}
                                options={categoryList}
                                getOptionLabel={option => option.name}
                                onChange={(event, values)=> setCategory(values)}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="category"
                                    placeholder="select a category"
                                    fullWidth
                                  />
                                )}
                              />
                        </div> 
                        <div className="mb3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                            inputVariant="outlined"
                            size="medium"
                            id="date-picker-inline"
                            className="MuiFormControl-fullWidth"
                            variant="outlined"
                            format="MM/dd/yyyy"
                            label="Survey End Date"
                            value={expectedEndDate}
                            onChange={(event , value) => {setExpectedEndDate(value)}}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="mb-3">
                            <Autocomplete className="my-3"
                                  id="Language"
                                  size="medium"
                                  options={LanguageObjList}
                                  getOptionLabel={option => option.name}
                                  defaultValue={language}
                                  onChange={(event, values) => values ?  setLanguage(values): null}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="language"
                                      placeholder="language"
                                      fullWidth
                                    />
                                  )}
                              />
                        </div>
                        <div className="mb-3">
                        <Button variant="outlined" color="primary" onClick={requestPannel}>Buy Panel</Button>
                        </div>
                  </div>
                  </CardContent>
                  </Card>
            </Grid>
            </Grid>
      </Fragment>
  );
}
