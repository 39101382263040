const getParentData = (data) => {

  const getGenderData = (arr)=> {

    let finalArr= []

    Object.keys(arr).forEach(function(key) {
      let x = parseInt(arr[key])
      if (x === 0){
        finalArr.push("Female")
      }
      else if (x === 1){
        finalArr.push("Male")
      }
      else if (x === 2){
        finalArr.push("others")
      }
      else if (x === -1){
        finalArr.push("prefer not to answer")
      }
    });
  return finalArr
  }
  let parent = {
    gender: getGenderData(data.gender),
    age: [],
    city: data.cityList,
    state: data.stateList,
    country: data.countryList
  };

  let demographyData = {
    gender: [],
    age: [],
    city: [],
    state: [],
    country:[]
  }
  return {
    parent: parent,
    data: demographyData
  }
}

const getFilteredData = (values, filter) => {
  return {};
}

export { getParentData, getFilteredData };