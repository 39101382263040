import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

export default (props) => {
  const [value, setValue] = useState(props.value);
  const [inputValue, setInputValue] = useState("");

  const handleValueChange = (event, values) => {
    setValue(values);
    props.onChange(values);
  }

  return (
    <Autocomplete 
      multiple={(props.isMultiple == null || props.isMultiple ==true) ? true : false}
      className={props.className || ""}
      // limitTags={props.limit}
      value={value}
      inputValue={inputValue}
      options={props.options}
      getOptionLabel={props.getOptionLabel}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={props.label} placeholder="" />
      )}
      onChange={handleValueChange}
      onInputChange={(event, value) => setInputValue(value)}
    />
  )
}