import React, { Fragment, useState, useEffect } from 'react';
import {
  Grid,

  Card,
  CardContent,
  TextField
} from '@material-ui/core';

export default (props) => {


const surveyTypes = {
  1:"Simple Survey",
  2:"Ad Testing",
  3:"Video Conference"
}

const [name, setName] = useState(props.surveyData.title);
const [description, setDescription] = useState(props.surveyData.notes ?? '');


  const handleNameChange = (name) => {
    setName(name)
    let survey = props.surveyData
    survey.title = name
    survey.edited = true
    props.editSurvey(survey)

  };
  const handleDescription = (notes) => {
    setDescription(notes)
    let survey = props.surveyData
    survey.notes = notes
    survey.edited = true
    props.editSurvey(survey)
  };



  return (
    <Fragment>
      <div className="card-body justify-content-between " style={{height:'75vh'}}>
        <Grid container spacing={4} alignItems="center" justify="center" >
            <Grid item xs={8} lg={8}>
                <Card className="mt-4">
                  <CardContent className="p-3">
                  <div className="justify-content-between mb-4">
                              <div className="mb-3">
                              <TextField
                                  variant="outlined"
                                  label="Name"
                                  fullWidth
                                  placeholder="Enter a Name"
                                  type="text"
                                  value={name}
                                  onChange={(event) => {handleNameChange(event.target.value)}}
                                  />
                                
                              </div>
                              <div className="mb-3">
                              <TextField
                                  variant="outlined"
                                  label="Description"
                                  fullWidth
                                  placeholder="Description"
                                  type="text"
                                  value={description}
                                  onChange={(event) => {handleDescription(event.target.value)}}
                                  />
                                
                              </div>
  
                  </div>
                  </CardContent>
                  </Card>
                  </Grid>
                  </Grid>
        </div>
      </Fragment>
      
  )
}