import React from 'react';
import Optiondashboard from './QuestionType/OptionsDashboard';
import DateDashboard from './QuestionType/DateDashboard';
import FileDashboard from './QuestionType/FileDashboard';
import TextDashboard from './QuestionType/TextDashboard';
import VideoEmotionDashboard from './QuestionType/VideoEmotionDashboard';
import LikertDashboard from './QuestionType/likertDashboard'
const DashboardConfig = {
  text: {
    name: "Input",
    id: "text",
    dashboard: (props) => <TextDashboard {...props} />
  },
  number: {
    name: "Number",
    id: "number",
    dashboard: (props) => <TextDashboard {...props} />
  },
  textarea: {
    name: "Text Box",
    id: "textarea",
    dashboard: (props) => <TextDashboard {...props} />
  },
  date: {
    name: "Date Only",
    id: "date",
    dashboard: (props) => <DateDashboard {...props} />
  },
  time: {
    name: "Time Only",
    id: "time",
    dashboard: (props) => <DateDashboard {...props} />
  },
  datetime: {
    name: "Date & Time",
    id: "datetime",
    dashboard: (props) => <DateDashboard {...props} />
  },
  file: {
    name: "File Upload",
    id: "file",
    dashboard: (props) => <FileDashboard {...props} type="file"/>
  },
  singleTextSelect: {
    name: "Single Text Selection",
    id: "singleTextSelect",
    dashboard: (props) => <Optiondashboard {...props} />
  },
  singleImageSelect: {
    name: "Single Image Selection",
    id: "singleImageSelect",
    dashboard: (props) => <Optiondashboard {...props} />
  },
  multiTextSelect: {
    name: "Multiple Text Selection",
    id: "multiTextSelect",
    dashboard: (props) => <Optiondashboard {...props} />
  },
  multipleImageSelect: {
    name: "Multiple Image Selection",
    id: "multipleImageSelect",
    dashboard: (props) => <Optiondashboard {...props} />
  },
  emotionIcons: {
    name: "Emotion Icons",
    id: "emotionIcons",
    dashboard: (props) => <Optiondashboard {...props} />
  },
  dropDown: {
    name: "Dropdown",
    id: "dropDown",
    dashboard: (props) => <Optiondashboard {...props} />
  },
  ratingSelect: {
    name: "Rating",
    id: "ratingSelect",
    dashboard: (props) => <Optiondashboard {...props} />
  },
  video: {
    name: "Video",
    id: "video",
    dashboard: (props) => <FileDashboard {...props} type="video" />
  },
  audio: {
    name: "Audio",
    id: "audio",
    dashboard: (props) => <FileDashboard {...props} type="audio"/>
  },
  videoEmotion: {
    name: "Video Emotion",
    id: "videoEmotion",
    dashboard: (props) => <VideoEmotionDashboard {...props} />
  },
  likert: {
    name: "likert",
    id: "likert",
    dashboard: (props) => <LikertDashboard {...props} />
  },
  matrix: {
    name: "Matrix",
    id: "matrix",
    dashboard: (props) => <LikertDashboard {...props} />
  }
}

export default DashboardConfig;