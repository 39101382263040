import React, { Fragment, PureComponent } from 'react';

import {
  Tabs,
  Tab,
  Box,
  Button,
  LinearProgress
} from '@material-ui/core';

import { Save, FeaturedPlayListTwoTone, CallReceived } from '@material-ui/icons';

import { PageTitle } from '../../../components';
import Design from './Design';
import Preview from './Preview';
import Panel from './Panel';
import TabPanel from '../../../components/TabPanel';
import { Survey } from '../../../Store/ApplicationStore';
import Setting from './Setting'

export default class Manage extends PureComponent {
  constructor(props) {
    super(props);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.state = {
      batchId: urlParams.get('id'),
      tab: 0,
      panel: {},
      questions: [],
      removedQuestions: [],
      title: "",
      submitProgress: false,
      surveyData:{},
      saveStatus:true,
    }
    this.questionIdsMapping = [];
    this.answerIdsMapping = [];
  }

  componentDidMount() {
    Survey.getQuestionIdsMapping().then((data) => {
      this.questionIdsMapping = data;
    });
    Survey.getAnswerIdsMapping().then((data) => {
      this.answerIdsMapping = data;
    });
    this.fetchAllQuestions();
    this.fetchSurveyDetails();
    this.interval = setInterval(() => { this.submitQuestions() }, 1000* 30);
  }
  
  
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchAllQuestions = () => {
    Survey.getAllQuestions(this.state.batchId).then((data) => {
      this.setState({
        questions: data,
        removedQuestions: []
      })
    })
  }
  
  fetchSurveyDetails = () => {
    Survey.getAllSurveys(this.state.batchId).then((data) => {
      if (data.length != 0){
        let dataOut = data[0]
        dataOut.edited = false
        this.setState({
          surveyData: dataOut,
          title : dataOut.title
        })
      }
    })
  }

  handleChange = (event, newValue) => {
    this.setState({
      tab: newValue
    })
  };

  editSurvey = (surveyData) =>{
    this.setState({
      surveyData : surveyData,
      title:surveyData.title
    })
    this.setState({
      saveStatus: false
    })
  }

  addQuestions = (answerType = false) => {
    let id = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
    let qObj = {
      feQId: id,
      questionId: false,
      questionText: "",
      questionType: 1,
      questionTypeName: "text",
      questionUrl: "",
      questionFile: false,
      answerType: answerType,
      condition: {
        status: false,
        list: []
      },
      orderId:0,
      questionObject: {}
    }
    switch (answerType) {
      case 'file':
      case 'singleTextSelect':
      case 'multiTextSelect':
      case 'singleImageSelect':
      case 'multiImageSelect':
        qObj.questionObject = {
          options: []
        }
    }
    this.setState((state, props) => {
      let newQues = state.questions.slice();
      newQues.push(qObj);
      return {
        questions: newQues
      }
    })
    this.setState({
      saveStatus: false
    })
  }


  addQuestionTemplate = (answerObj) => {
    let id = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
    answerObj.feQId = id
    if (answerObj.condition.hasOwnProperty("list")){
      answerObj.condition.list.map((e, i) => {
        if(e.hasOwnProperty('list')){
          for (let x = 0 ; x < e.list.length ; x++){
            if(e.list[x].hasOwnProperty('question')){
              e.list[x]['question'] = id
            }
          }
          }
        })
      }
    answerObj.condition.list[0].list[0].question =  id
    this.setState((state, props) => {
      let newQues = state.questions.slice();
      newQues.push(answerObj);
      return {
        questions: newQues
      }
    })
    this.setState({
      saveStatus: false
    })
  }

  modifyQuestion = (index, obj) => {
    let questions = [ ...this.state.questions ];
    questions[index] = { ...obj, edited: true };
    if (obj.hasOwnProperty('questionTypeName')) {
      let qTypeName = obj.questionTypeName;
      for(let i = 0; i < this.questionIdsMapping.length; i++) {
        let lowercaseText = this.questionIdsMapping[i]["name"].toLowerCase();
        if (lowercaseText == qTypeName) {
          questions[index]["questionType"] = this.questionIdsMapping[i]["id"];
          break;
        }
      }
    }
    this.setState({
      questions: questions
    })
    this.setState({
      saveStatus: false
    })
  }

  moveQuestion = (from, to) => {
    let newQuestions = [ ...this.state.questions ];
    newQuestions.splice(from, 1);
    newQuestions.splice(to, 0, { ...this.state.questions[from] });
    for (let x = 0 ; x < newQuestions.length; x++){
      newQuestions[x].orderId = x + 1
      newQuestions[x].edited = true
    }
    this.setState({
      questions: newQuestions
    })
    this.setState({
      saveStatus: false
    })
    
  }

  removeQuestion = (index) => {
    let newQuestions = [ ...this.state.questions ];
    let remQues = newQuestions.splice(index, 1);
    let removedQuestions = remQues.concat(this.state.removedQuestions);
    this.setState({
      questions: newQuestions,
      removedQuestions: removedQuestions
    })
    this.setState({
      saveStatus: false
    })
  }

  submitQuestions = () => {
    this.setState({
      submitProgress: true
    })
    Survey.submitQuestions(this.state.questions, this.state.removedQuestions, this.state.batchId).then((data) => {
      this.fetchAllQuestions();
      this.setState({
        submitProgress: false
      })
    

      if (this.state.surveyData.edited === true){
        Survey.updateExistingSurvey(this.state.surveyData)
      }
      
    }).catch((err) => {
      console.log(err);
    })
  }

  
  submitDisabled = () => {
    if (this.state.removedQuestions.length > 0) {
      return false;
    }
    for(let i = 0; i < this.state.questions.length; i++) {
      if (this.state.questions[i]["questionId"] === false) {
        return false;
      }
      else if (this.state.questions[i]["questionId"] && this.state.questions[i]["edited"]) {
        return false;
      }
    }
    return true;
  }

  render() {
    return (
      <div className="d-flex flex-column" >
        <PageTitle 
          titleHeading={this.state.title}
          titleDescription=""
          pageTitleComponentRight={
          <Button 
          className="mt-2"
            color="primary"
            variant="contained"
            startIcon={<Save />}
            onClick={this.submitQuestions}
            disabled={this.state.saveStatus}
          >
            Save
          </Button>}
       />
       {this.state.submitProgress ? <LinearProgress /> : null}
        <Box bgcolor="white" p={0} m={0} >
          <Tabs
            value={this.state.tab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            onChange={this.handleChange}>
            <Tab className="text-capitalize p-4" label="Design QNR" />
            <Tab className="text-capitalize p-4" label="Panel" />
            <Tab className="text-capitalize p-4" label="Preview" />
            <Tab className="text-capitalize p-4" label="Setting" />
          </Tabs>
          <Box borderTop={0.5} >
            <TabPanel value={this.state.tab} index={0}>
              <Design questions={this.state.questions} moveQuestion={this.moveQuestion} modifyQuestion={this.modifyQuestion} removeQuestion={this.removeQuestion} addQuestions={this.addQuestions} addQuestionTemplate={this.addQuestionTemplate}/>
            </TabPanel>
            <TabPanel value={this.state.tab} index={1}>
              <Panel id={this.state.batchId} name={this.state.title} surveyData={this.state.surveyData} editSurvey={this.editSurvey}/>
            </TabPanel>
            <TabPanel value={this.state.tab} index={2}>
              <Preview questions={this.state.questions} surveyData={this.state.surveyData} editSurvey={this.editSurvey}/>
            </TabPanel>
            <TabPanel value={this.state.tab} index={3}>
              <Setting surveyData={this.state.surveyData} editSurvey={this.editSurvey}/>
            </TabPanel>
          </Box>
        </Box>
      </div>
    )
  }
}